import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';
import type ICategoryState from './interfaces/ICategoryState';

export const initialState: ICategoryState = {
  isOpenModal: false,
  categoryId: undefined,
};

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    openCreateCategoryModal: (state) => {
      state.isOpenModal = true;
      state.categoryId = undefined;
    },
    openEditCategoryModal: (state, action: PayloadAction<number>) => {
      state.isOpenModal = true;
      state.categoryId = action.payload;
    },
    closeCategoryModal: (state) => {
      state.isOpenModal = false;
    },
  },
});

export const { openCreateCategoryModal, openEditCategoryModal, closeCategoryModal } = categorySlice.actions;

export default categorySlice.reducer;
