import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { useSwiper } from 'swiper/react';

import type IActionsPanel from './interfaces/IActionsPanel';

const buttonStyles = {
  width: 40,
  height: 40,
};

function ActionsPanel({
  isButtonDisabled,
  onFlip,
  onSelectAnswer,
}: IActionsPanel) {
  const { t } = useTranslation();
  const swiper = useSwiper();

  const handleResetFlip = useCallback(() => onFlip(null), [onFlip]);

  const swipeToNext = () => swiper.slideNext();

  const onHandleSelectWrong = () => {
    onSelectAnswer(false);
    swipeToNext();
  };

  const onHandleSelectRight = () => {
    onSelectAnswer(true);
    swipeToNext();
  };

  useEffect(() => {
    swiper.on('slideChangeTransitionEnd', handleResetFlip);

    return () => {
      swiper.off('slideChangeTransitionEnd', handleResetFlip);
    };
  }, [handleResetFlip, swiper]);

  return (
    <Stack
      direction="row"
      spacing={3}
      justifyContent="center"
    >
      <IconButton
        aria-label="not match"
        disabled={isButtonDisabled}
        sx={(theme) => ({
          ...buttonStyles,
          backgroundColor: theme.palette.error?.main,
          '&:hover': {
            backgroundColor: theme.palette.error?.main,
          },
        })}
        onClick={onHandleSelectWrong}
      >
        <RemoveRoundedIcon color="secondary" fontSize="small" />
      </IconButton>
      <Button
        variant="outlined"
        startIcon={<CachedRoundedIcon color="inherit" />}
        sx={(theme) => ({
          color: theme.palette.text.secondary,
        })}
        onClick={() => onFlip(swiper.activeIndex)}
      >
        {t('study.flipCard')}
      </Button>
      <IconButton
        aria-label="match"
        disabled={isButtonDisabled}
        sx={(theme) => ({
          ...buttonStyles,
          backgroundColor: theme.palette.success?.main,
          '&:hover': {
            backgroundColor: theme.palette.success?.main,
          },
        })}
        onClick={onHandleSelectRight}
      >
        <CheckRoundedIcon color="secondary" fontSize="small" />
      </IconButton>
    </Stack>
  );
}

export default ActionsPanel;
