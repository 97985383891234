import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import Dropdown from '../../components/Dropdown/Dropdown';
import mainApi from '../../store/apis/mainApi';
import { useAppDispatch } from '../../store/hooks/useApp';
import { removeTokens } from '../../store/slices/auth/slice';

function ProfileMenu() {
  const { t } = useTranslation();
  const { user, logout } = useAuth0();
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const onSignOut = async () => {
    await logout({ logoutParams: { returnTo: window.location.origin } });
    dispatch(removeTokens());
    dispatch(mainApi.util.resetApiState());
  };

  return user ? (
    <>
      <Button
        sx={{
          display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1,
        }}
        aria-controls={open ? 'dropdown-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        aria-label="open dropdown"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <Avatar src={user.picture} alt={user.name} />
        <Typography color="text.secondary">{user.name}</Typography>
        <ExpandMoreRoundedIcon />
      </Button>
      <Dropdown
        id="dropdown-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'dropdown-button',
        }}
        anchorOrigin={{
          vertical: 60,
          horizontal: 0,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={onSignOut}>
          <LogoutRoundedIcon />
          {t('common.signOut')}
        </MenuItem>
      </Dropdown>
    </>
  ) : null;
}

export default ProfileMenu;
