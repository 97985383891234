import { useState } from 'react';
import { Controller, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import AIModal from '../../../../components/AIModal/AIModal';
import CreateFolderModal from '../../../../components/CreateFolderModal/CreateFolderModal';
import Panel from '../../../../components/Panel/Panel';
import DeckSectionHead from './DeckSectionHead';

import type { Theme } from '@mui/material/styles';
import type ICategory from '../../../../store/slices/category/interfaces/ICategory';
import type IFlashcard from '../../../../store/slices/deck/interfaces/IFlashcard';
import type IDeckSection from './interfaces/IDeckSection';

import { ReactComponent as StarsIcon } from '../../../../assets/images/stars.svg';

const hoverBoxStyles = (theme: Theme) => ({
  position: 'absolute',
  width: 'calc(100% - 32px)',
  height: 40,
  padding: '9px 0 9px 16px',
  top: 0,
  left: 0,
  zIndex: 100,
  fontSize: 14,
  '& > span': {
    display: 'block',
    color: theme.palette.primary.light,
    backgroundColor: 'white',
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
});

function DeckSection({
  control,
  categories,
  isEdit,
  onConvertText,
}: IDeckSection) {
  const { t } = useTranslation();

  const [isOpenCategoryModal, setIsOpenCategoryModal] = useState(false);
  const [openAIModal, setOpenAIModal] = useState<boolean>(false);
  const [hint, setHint] = useState<string>('');
  const [stop, setStop] = useState<boolean>(false);

  const { field: { onChange: onChangeCategory } } = useController({ name: 'categoryID', control });

  const onHandleClose = () => {
    setStop(false);
    setHint('');
  };

  const onHandleMouseEnter = (name: string) => {
    if (stop) {
      setHint(name);
    }
  };

  const onConfirmConvert = (flashcardItems: IFlashcard[]) => {
    setOpenAIModal(false);
    onConvertText?.(flashcardItems);
  };

  const onCreateFolder = ({ categoryID }: ICategory) => {
    onChangeCategory(categoryID);
  };

  return (
    <Panel>
      <DeckSectionHead>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap={2}
          py={3}
          px={2}
        >
          <Typography
            variant="h2"
            fontSize={20}
            fontWeight={600}
            lineHeight="normal"
          >
            {isEdit ? t('decks.updateDeck') : t('decks.createDeck')}
          </Typography>
          <Button
            variant="contained"
            startIcon={<StarsIcon />}
            sx={{
              padding: '6.5px 16px',
              fontSize: 12,
            }}
            onClick={() => setOpenAIModal(true)}
          >
            {t('decks.assistantAI')}
          </Button>
        </Stack>
        <Divider />
        <Grid
          container
          py={3}
          px={2}
          spacing={2}
        >
          <Grid item xs={12} lg={6}>
            <Typography
              mb={1}
              fontSize={14}
              fontWeight={600}
              color="text.primary"
            >
              {t('decks.name')}
            </Typography>
            <Controller
              name="deckName"
              control={control}
              render={({
                field,
                fieldState: { error: fieldError },
              }) => (
                <FormControl error={!!fieldError} fullWidth>
                  <TextField
                    helperText={fieldError?.message}
                    error={!!fieldError}
                    placeholder={t('decks.deckNameHint')}
                    fullWidth
                    {...field}
                  />
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography
              mb={1}
              fontSize={14}
              fontWeight={600}
              color="text.primary"
            >
              {t('decks.saveToFolder')}
            </Typography>
            <Controller
              name="categoryID"
              control={control}
              render={({
                field: { onChange, value, onBlur },
                fieldState: { error: fieldError },
              }) => (
                <FormControl error={!!fieldError} fullWidth sx={{ position: 'relative' }}>
                  {hint ? (
                    <Box sx={hoverBoxStyles}>
                      <span>{hint}</span>
                    </Box>
                  ) : null}
                  <Select
                    displayEmpty
                    disabled={isEdit}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    onClose={onHandleClose}
                    onOpen={() => setStop(true)}
                    IconComponent={ExpandMoreRoundedIcon}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 43,
                        horizontal: 'center',
                      },
                      MenuListProps: {
                        sx: {
                          maxHeight: 200,
                          padding: '8px 8px 12px',
                        },
                      },
                    }}
                  >
                    <Button
                      variant="text"
                      sx={{
                        width: '100%',
                        padding: '12px 16px',
                        justifyContent: 'start',
                      }}
                      startIcon={<AddRoundedIcon />}
                      onClick={() => setIsOpenCategoryModal(true)}
                    >
                      {t('decks.createNewFolder')}
                    </Button>
                    <Divider sx={{ mt: 1.5, mb: 1 }} />
                    {categories.map(({ categoryName, categoryID }) => (
                      <MenuItem
                        key={categoryID}
                        value={categoryID}
                        sx={{
                          padding: '9px 16px',
                          fontSize: 14,
                        }}
                        onMouseEnter={() => onHandleMouseEnter(categoryName)}
                        onMouseLeave={() => setHint('')}
                      >
                        {categoryName}
                      </MenuItem>
                    ))}
                  </Select>
                  {fieldError?.message
                    ? <FormHelperText>{fieldError.message}</FormHelperText>
                    : null}
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
      </DeckSectionHead>

      <AIModal
        open={openAIModal}
        onConfirmClose={() => setOpenAIModal(false)}
        onSave={onConfirmConvert}
      />

      <CreateFolderModal
        open={isOpenCategoryModal}
        onClose={() => setIsOpenCategoryModal(false)}
        onSave={onCreateFolder}
      />
    </Panel>
  );
}

export default DeckSection;
