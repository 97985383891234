import {
  type ReactElement, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';

import usePageLoader from '../../hooks/usePageLoader';
import { useAppDispatch, useAppSelector } from '../../store/hooks/useApp';
import { useDecksQuery, useOneDeckQuery } from '../../store/slices/deck/apis/mainDeckApi';
import {
  useSelectAnswerMutation,
  useStudysessionEndMutation,
  useStudysessionStartMutation,
} from '../../store/slices/study/apis/mainStudyApi';
import selectAnswers from '../../store/slices/study/selectors';
import { resetState } from '../../store/slices/study/slice';
import StudyView from './StudyView';

function Study(): ReactElement {
  const { deckId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const answers = useAppSelector(selectAnswers);

  const { data: allDecks, isLoading } = useDecksQuery();
  const { data: deck, isLoading: isLoadingDeck } = useOneDeckQuery(deckId ? +deckId : skipToken);
  const [sessionStart, { data: session, isSuccess }] = useStudysessionStartMutation();
  const [sessionEnd, { data: endSession }] = useStudysessionEndMutation();
  const [selectAnswer] = useSelectAnswerMutation();

  usePageLoader(isLoading);

  const [open, setOpen] = useState(false);

  const foundDeck = useMemo(
    () => (deckId
      ? allDecks?.find((deckItem) => deckItem.deckID === +deckId)
      : undefined),
    [allDecks, deckId],
  );
  const haveAllAnswers = useMemo(() => answers.length === deck?.flashcardItems.length, [answers, deck]);

  const onSessionStart = () => {
    if (deckId) {
      sessionStart({ deckId: +deckId });
    }
  };

  const onSessionEnd = useCallback(async () => {
    if (deckId && session) {
      await sessionEnd({
        deckId: +deckId,
        sessionId: session.sessionID,
      }).unwrap();
    }
  }, [deckId, session, sessionEnd]);

  const onSelectAnswer = (cardId: number, answer: boolean) => {
    if (session?.sessionID) {
      selectAnswer({
        sessionId: session.sessionID,
        flashcardId: cardId,
        correctlyAnswered: answer,
      });
    }
  };

  const onHandleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    const updateData = async () => {
      await onSessionEnd();
      setOpen(true);
    };

    if (haveAllAnswers) {
      updateData();
    }
  }, [haveAllAnswers, session, onSessionEnd]);

  useEffect(() => () => {
    dispatch(resetState());
  }, [dispatch]);

  return (
    <StudyView
      deck={foundDeck || deck}
      isLoadingDeck={isLoadingDeck}
      showModal={open}
      answers={answers}
      correctAnswers={(endSession && endSession.sessionID === session?.sessionID) ? endSession.sessionScore * 100 : 0}
      showCards={isSuccess}
      onSessionStart={onSessionStart}
      onSelectAnswer={onSelectAnswer}
      onConfirm={onHandleClose}
      onClose={onHandleClose}
    />
  );
}

export default Study;
