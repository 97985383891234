import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import AppRoutes from '../../constants/AppRoutes';

import type { Theme } from '@mui/material';
import type { ReactElement } from 'react';
import type IStudySidebar from './interfaces/IStudySidebar';

import { ReactComponent as CardsIcon } from '../../assets/images/cards.svg';
import { ReactComponent as LogoIcon } from '../../assets/images/logo.svg';

const buttonStyles = (theme: Theme) => ({
  justifyContent: 'start',
  padding: '10px 24px 10px 46px',
  borderRadius: 'unset',
  color: theme.palette.primary.light,
  fontWeight: 400,
  lineHeight: 'initial',
});

const itemStyles = (theme: Theme) => ({
  padding: '10px 24px 10px 46px',
  backgroundColor: theme.palette.secondary.main,
});

function StudySidebar({ drawerWidth, deckName }: IStudySidebar): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onHandleClick = () => navigate(-1);

  const onClickLogo = () => navigate(AppRoutes.home);

  return (
    <Drawer
      component="nav"
      variant="permanent"
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: drawerWidth,
        },
      }}
      open
    >
      <Toolbar>
        <ButtonBase onClick={onClickLogo} disableRipple>
          <LogoIcon />
        </ButtonBase>
      </Toolbar>
      <Stack pt="20px">
        <Button
          variant="text"
          startIcon={<ChevronLeftRoundedIcon color="inherit" />}
          sx={buttonStyles}
          onClick={onHandleClick}
        >
          {t('common.back')}
        </Button>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={itemStyles}
        >
          <CardsIcon />
          <Typography
            variant="body2"
          >
            {deckName || ''}
          </Typography>
        </Stack>
      </Stack>
    </Drawer>
  );
}

export default StudySidebar;
