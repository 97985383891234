import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeIcon from '@mui/icons-material/Mode';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CardActions from '@mui/material/CardActions';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';

import AppRoutes from '../../constants/AppRoutes';
import { useAppDispatch } from '../../store/hooks/useApp';
import { useDeleteCategoryMutation } from '../../store/slices/category/apis/mainCategoryApi';
import { openEditCategoryModal } from '../../store/slices/category/slice';
import DeleteModal from '../DeleteModal/DeleteModal';
import Dropdown from '../Dropdown/Dropdown';
import FolderChip from '../FolderChip/FolderChip';
import Subtitle from '../Subtitle/Subtitle';

import type { SyntheticEvent } from 'react';
import type IFolder from './interfaces/IFolder';

import styles from './sass/Folder.module.scss';

function Folder({
  categoryId,
  color,
  title,
  cardsCount,
  decksCount,
}: IFolder) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [deleteCategory, { isLoading }] = useDeleteCategoryMutation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const open = Boolean(anchorEl);

  const onHandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const onHandleClose = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  };

  const onDeleteFolder = async () => {
    await deleteCategory(categoryId);
    setIsOpenDeleteModal(false);
  };

  const onOpenConfirmDelete = (event: SyntheticEvent) => {
    onHandleClose(event);
    setIsOpenDeleteModal(true);
  };

  const onCloseConfirmDelete = () => {
    setIsOpenDeleteModal(false);
  };

  const onEditFolder = (event: SyntheticEvent) => {
    onHandleClose(event);
    dispatch(openEditCategoryModal(categoryId));
  };

  return (
    <>
      <Stack
        sx={{ backgroundColor: color }}
        className={styles.card}
        spacing={2}
        role="button"
        onClick={() => navigate(AppRoutes.buildDecksInFolder(categoryId))}
      >
        <Subtitle
          className={styles.cardTitle}
          text={title}
        />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" spacing={0.5}>
            <FolderChip label={t('decks.cards', { count: cardsCount })} />
            <FolderChip label={t('decks.decks', { count: decksCount })} />
          </Stack>
          <CardActions sx={{ p: 0 }}>
            <IconButton
              className={styles.cardDropdown}
              id="dropdown-button"
              aria-controls={open ? 'dropdown-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              aria-label="open dropdown"
              onClick={onHandleClick}
            >
              <MoreVertIcon
                sx={{ fontSize: 20, color: '#ffffff' }}
              />
            </IconButton>
            <Dropdown
              id="dropdown-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={onHandleClose}
              MenuListProps={{
                'aria-labelledby': 'dropdown-button',
              }}
              anchorOrigin={{
                vertical: 50,
                horizontal: 30,
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem onClick={onEditFolder}>
                <ModeIcon />
                {t('common.edit')}
              </MenuItem>
              <Divider sx={{ my: 0.5 }} />
              <MenuItem onClick={onOpenConfirmDelete}>
                <DeleteIcon />
                {t('common.delete')}
              </MenuItem>
            </Dropdown>
          </CardActions>
        </Stack>
      </Stack>
      <DeleteModal
        isSaving={isLoading}
        open={isOpenDeleteModal}
        onClose={onCloseConfirmDelete}
        onConfirm={onDeleteFolder}
      />
    </>
  );
}

export default Folder;
