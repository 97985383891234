import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import type { StackProps } from '@mui/material/Stack';

const ErrorBanner = styled(Stack)<StackProps>(({ theme }) => ({
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.secondary.light}`,
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.error?.light,
  boxShadow: '0px 8px 8px -4px rgba(0, 0, 0, 0.03), 0px 20px 24px -4px rgba(0, 0, 0, 0.08)',
}));

export default ErrorBanner;
