import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Modal from '../Modal/Modal';

import type IDeleteModal from './interfaces/IDeleteModal';

import { ReactComponent as AlertIcon } from '../../assets/images/alert.svg';

import styles from './sass/DeleteModal.module.scss';

function DeleteModal({
  open,
  onClose,
  onConfirm,
  isSaving,
}: IDeleteModal) {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      title={t('modal.delete')}
      confirmTitle={t('modal.actionDelete')}
      onClose={onClose}
      onConfirm={onConfirm}
      showCancel
      isSaving={isSaving}
    >
      <Stack
        className={styles.root}
        alignItems="center"
      >
        <Stack
          className={styles.wrapper}
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <AlertIcon />
          <Typography
            textAlign="center"
            variant="body1"
            fontWeight={700}
          >
            {t('modal.deleteMsgTitle')}
          </Typography>
          <Typography
            textAlign="center"
            variant="body2"
            color="text.secondary"
          >
            {t('modal.deleteMsgText')}
          </Typography>
        </Stack>
      </Stack>
    </Modal>
  );
}

export default DeleteModal;
