const AppRoutes = {
  landing: '/',
  home: '/dashboard',
  login: '/login',
  privacy: '/privacy',
  terms: '/terms',
  contact: '/contact',
  about: '/about',
  folders: '/folders',
  decks: '/decks',
  decksInFolder: '/folder/:id',
  buildDecksInFolder: (id: number) => AppRoutes.decksInFolder.replace(':id', String(id)),
  decksCreate: '/decks/create',
  decksEdit: '/decks/edit/:deckId',
  buildDecksEdit: (id: number) => AppRoutes.decksEdit.replace(':deckId', String(id)),
  studySession: '/study-session/:deckId',
  buildStudySession: (id: number) => AppRoutes.studySession.replace(':deckId', String(id)),
};

export default AppRoutes;
