import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import type ILoaderBackdrop from './interfaces/ILoaderBackdrop';

function LoaderBackdrop({ open }: ILoaderBackdrop) {
  return (
    <Backdrop
      open={open}
      sx={{ color: 'secondary.light', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default LoaderBackdrop;
