import CardHeader from '@mui/material/CardHeader';
import { styled } from '@mui/material/styles';

const CardHeaderDeck = styled(
  CardHeader,
)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  padding: 0,
  '& .MuiTypography-root': {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(16),
  },
}));

export default CardHeaderDeck;
