import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { DateTime } from 'luxon';

import { useActivitiesQuery } from '../../store/slices/study/apis/mainStudyApi';
import ActivityBarRoot from './ActivityBarRoot';
import BarItem from './BarItem';

import type IActivity from '../../store/slices/study/interfaces/IActivity';

const now = DateTime.local({ locale: 'en-US' }).startOf('week');

const week = [
  now.minus({ days: 1 }),
  now,
  now.plus({ days: 1 }),
  now.plus({ days: 2 }),
  now.plus({ days: 3 }),
  now.plus({ days: 4 }),
  now.plus({ days: 5 }),
];

const checkActivityBar = (day: DateTime, activities: IActivity[]) => activities
  ?.some(({ studiedAt }) => {
    const activityDate = studiedAt;
    return activityDate && DateTime.fromISO(activityDate).startOf('day').toMillis() === day.toMillis();
  });

function ActivityBar() {
  const { t } = useTranslation();

  const { data: activities } = useActivitiesQuery();
  return (
    <ActivityBarRoot elevation={0}>
      <Stack
        spacing={3}
        direction="row"
        justifyContent="space-between"
        sx={{ height: '100%' }}
      >
        <Typography
          variant="h2"
          fontSize={20}
          fontWeight={600}
          sx={{ maxWidth: 123 }}
        >
          {t('home.weekActivity')}
        </Typography>
        <Stack spacing={1.5} direction="row">
          {week.map((day) => (
            <Stack
              key={day.weekdayShort}
              spacing={0.5}
              direction="column"
              alignItems="center"
              sx={{ height: '100%' }}
            >
              <BarItem isFilled={!!activities && checkActivityBar(day, activities)} />
              <Typography
                fontSize={12}
                fontWeight={500}
                sx={{ opacity: 0.5 }}
              >
                {day.weekdayShort}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </ActivityBarRoot>
  );
}

export default ActivityBar;
