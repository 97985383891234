import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import FolderRoundedIcon from '@mui/icons-material/FolderRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import LayersRoundedIcon from '@mui/icons-material/LayersRounded';
import ButtonBase from '@mui/material/ButtonBase';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { TreeView } from '@mui/x-tree-view/TreeView';

import AppRoutes from '../../constants/AppRoutes';
import { useAppDispatch } from '../../store/hooks/useApp';
import { useCategoriesQuery } from '../../store/slices/category/apis/mainCategoryApi';
import { openCreateCategoryModal } from '../../store/slices/category/slice';
import FolderItem from './FolderItem';
import SidebarLabel from './SidebarLabel';

import type { ReactElement, SyntheticEvent } from 'react';
import type ISidebar from './interfaces/ISidebar';

import { ReactComponent as LogoIcon } from '../../assets/images/logo.svg';

function Sidebar({ drawerWidth }: ISidebar): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { pathname } = location;

  const { data: categories } = useCategoriesQuery();

  const onNodeSelect = (e: SyntheticEvent, nodeId: string) => {
    navigate(nodeId);
  };

  const onOpenCreateCategoryModal = (e?: SyntheticEvent) => {
    e?.preventDefault();
    e?.stopPropagation();
    dispatch(openCreateCategoryModal());
  };

  const onClickLogo = () => navigate(AppRoutes.home);

  return (
    <Drawer
      component="nav"
      variant="permanent"
      sx={{
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
      }}
      open
    >
      <Toolbar>
        <ButtonBase onClick={onClickLogo} disableRipple>
          <LogoIcon />
        </ButtonBase>
      </Toolbar>
      <TreeView
        defaultCollapseIcon={<KeyboardArrowDownRoundedIcon />}
        defaultExpandIcon={<KeyboardArrowRightRoundedIcon />}
        sx={{ mx: theme.spacing(2) }}
        onNodeSelect={onNodeSelect}
        selected={pathname}
      >
        <TreeItem
          nodeId={AppRoutes.home}
          label={(
            <SidebarLabel
              label={t('common.home')}
              startIcon={<DashboardRoundedIcon fontSize="small" />}
            />
          )}
        />
        <TreeItem
          nodeId={AppRoutes.decks}
          label={(
            <SidebarLabel
              label={t('common.myDecks')}
              startIcon={<LayersRoundedIcon fontSize="small" />}
            />
          )}
        />
        <TreeItem
          nodeId={AppRoutes.folders}
          label={(
            <SidebarLabel
              label={t('common.myFolders')}
              startIcon={<FolderRoundedIcon fontSize="small" />}
              endIcon={<IconButton onClick={onOpenCreateCategoryModal}><AddRoundedIcon color="primary" /></IconButton>}
            />
          )}
        >
          {categories?.map((folder) => (
            <FolderItem
              key={folder.categoryID}
              categoryID={folder.categoryID}
              categoryName={folder.categoryName}
              colorCode={folder.colorCode}
            />
          ))}
        </TreeItem>
      </TreeView>
    </Drawer>
  );
}

export default Sidebar;
