import { createTheme } from '@mui/material/styles';

import type {} from '@mui/x-tree-view/themeAugmentation';

const blue = {
  500: '#2970FF',
};

const red = {
  100: '#FFEEED',
  500: '#F04438',
};

const lime = {
  500: '#66C61C',
};

const grey = {
  100: '#F8FAFC',
  200: '#EAECF0',
  300: '#D0D5DD',
  400: '#98A2B3',
  500: '#667085',
  600: '#475467',
  700: '#344054',
  800: '#1D2939',
  900: '#101828',
};

const appTheme = createTheme({
  palette: {
    primary: {
      main: grey[900],
      light: grey[400],
    },
    secondary: {
      main: grey[100],
      light: grey[200],
    },
    text: {
      primary: grey[900],
      secondary: grey[600],
      disabled: grey[400],
    },
    error: {
      main: red[500],
      light: red[100],
    },
    success: {
      main: lime[500],
    },
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          borderStyle: 'solid',
          borderWidth: 1,
          borderColor: 'transparent',
          '&:hover': {
            borderColor: grey[200],
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          fontSize: 14,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: 8,
          borderStyle: 'solid',
          borderWidth: 1,
          borderColor: grey[200],
        },
        list: ({ theme }) => ({
          padding: theme.spacing(1),
        }),
      },
    },
    MuiTreeItem: {
      styleOverrides: {
        label: ({ theme }) => ({
          marginTop: theme.spacing(1),
          marginBottom: theme.spacing(1),
        }),
        group: {
          borderLeftColor: grey[200],
        },

        content: {
          '& .MuiButton-root': {
            color: grey[400],
            '&:hover': {
              color: grey[600],
            },
          },
          '&.Mui-selected': {
            backgroundColor: 'transparent',
            '& .MuiButton-root': {
              color: grey[900],
            },
            '&:hover': {
              backgroundColor: 'transparent',
            },
            '&.Mui-focused': {
              backgroundColor: grey[100],
            },
          },
          color: grey[400],
          '&:hover': {
            color: grey[600],
            backgroundColor: 'transparent',
          },
          '&.Mui-focused': {
            backgroundColor: grey[100],
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          padding: 10,
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: blue[500],
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: 132,
          padding: 9,
          fontWeight: 600,
          lineHeight: '160%',
          textTransform: 'none',
          borderRadius: 8,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          boxShadow: 'none',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 12,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            padding: 'initial',
          },
          '& .MuiInputBase-input': {
            height: 'unset',
            padding: '9px 16px',
            fontSize: 14,
            lineHeight: '160%',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          padding: 0,
          fontSize: 14,
          lineHeight: '160%',
          '& .MuiSelect-select': {
            padding: '9px 16px',
          },
          '& .MuiSvgIcon-root': {
            right: 10,
            color: 'initial',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 'unset',
        },
      },
    },
  },
});

export default appTheme;
