import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import AppRoutes from '../../constants/AppRoutes';
import { MOTIVATION_STATUS } from '../../constants/Common';
import usePageLoader from '../../hooks/usePageLoader';
import { useAppDispatch } from '../../store/hooks/useApp';
import { useCategoriesQuery } from '../../store/slices/category/apis/mainCategoryApi';
import { openCreateCategoryModal } from '../../store/slices/category/slice';
import { useDecksQuery } from '../../store/slices/deck/apis/mainDeckApi';
import { useActivitiesQuery } from '../../store/slices/study/apis/mainStudyApi';
import DashboardView from './DashboardView';

import type { ReactElement } from 'react';
import type IDeck from '../../store/slices/deck/interfaces/IDeck';
import type ICategoriesById from '../Decks/interfaces/ICategoriesById';
import type IDecksByFolders from '../Folders/interfaces/IDecksByFolders';
import type ICategoryWithCount from './interfaces/ICategoryWithCount';
import type IDecksById from './interfaces/IDecksById';

function Dashboard(): ReactElement {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { data: activities, isLoading: isLoadingActivities } = useActivitiesQuery();
  const { data: decks, isLoading: isLoadingDecks } = useDecksQuery();
  const { data: categories, isLoading: isLoadingCategories } = useCategoriesQuery();

  usePageLoader(isLoadingActivities || isLoadingDecks || isLoadingCategories);

  const categoriesById = useMemo(() => categories
    ?.reduce((acc: ICategoriesById, category) => ({
      ...acc,
      [category.categoryID]: category,
    }), {}), [categories]);

  const deckById = useMemo(() => decks
    ?.reduce((acc: IDecksById, deck) => ({
      ...acc,
      [deck.deckID]: { ...deck, category: categoriesById?.[deck.categoryID] },
    }), {}), [decks, categoriesById]);

  const lastDecks = useMemo(
    () => activities?.map(({ deckID }) => deckById?.[deckID])?.filter(Boolean) as IDeck[],
    [activities, deckById],
  );

  const decksByFolders = useMemo(
    () => decks?.reduce((acc: IDecksByFolders, deck) => ({
      ...acc,
      [deck.categoryID]: {
        decksCount: (acc?.[deck.categoryID]?.decksCount || 0) + 1,
        cardsCount: (acc?.[deck.categoryID]?.cardsCount || 0) + (deck.flashcardItems?.length || 0),
      },
    }), {}),
    [decks],
  );

  const lastCategories = useMemo(
    () => (categoriesById && decksByFolders
      ? activities
        ?.map(({ categoryID }) => categoryID)
        .filter((item, index, array) => array.indexOf(item) === index)
        .map((categoryID) => ({
          ...(categoriesById?.[categoryID] || {}),
          ...(decksByFolders?.[categoryID] || {}),
        }))?.filter(Boolean) as ICategoryWithCount[]
      : []),
    [activities, decksByFolders, categoriesById],
  );

  const onCreateDeck = () => {
    navigate(AppRoutes.decksCreate);
  };

  const onCreateFolder = () => {
    dispatch(openCreateCategoryModal());
  };

  const motivationStatus = useMemo(() => {
    if (!activities?.length) {
      return MOTIVATION_STATUS.NO_ACTIVITIES;
    } if (activities.some(({ studiedAt }) => !!studiedAt)) {
      return MOTIVATION_STATUS.WELL_DONE;
    }
    return MOTIVATION_STATUS.NO_STUDY_SESSIONS;
  }, [activities]);

  return (
    <DashboardView
      decks={lastDecks}
      categories={lastCategories}
      onCreateDeck={onCreateDeck}
      onCreateFolder={onCreateFolder}
      motivationStatus={motivationStatus}
    />
  );
}

export default Dashboard;
