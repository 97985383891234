import { useMemo } from 'react';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';

import AppRoutes from '../../constants/AppRoutes';
import usePageLoader from '../../hooks/usePageLoader';
import { useCategoriesQuery } from '../../store/slices/category/apis/mainCategoryApi';
import { useDecksQuery } from '../../store/slices/deck/apis/mainDeckApi';
import DecksView from './DecksView';

import type { ReactElement } from 'react';
import type ICategoriesById from './interfaces/ICategoriesById';

function Decks(): ReactElement {
  const navigate = useNavigate();

  const { id } = useParams();
  const { data: categories, isLoading: isLoadingCategories } = useCategoriesQuery();
  const { data: decks, isLoading: isLoadingDecks } = useDecksQuery();

  usePageLoader(isLoadingCategories || isLoadingDecks);

  const decksInFolder = useMemo(
    () => (id ? decks?.filter(({ categoryID }) => categoryID === +id) : decks),
    [id, decks],
  );

  const categoriesById = useMemo(() => categories
    ?.reduce((acc: ICategoriesById, category) => ({
      ...acc,
      [category.categoryID]: category,
    }), {}), [categories]);

  const currentCategory = id ? categoriesById?.[+id] : undefined;

  const onCreateDeck = () => {
    navigate({
      pathname: AppRoutes.decksCreate,
      search: id ? `?${createSearchParams({ categoryId: id })}` : '',
    });
  };

  return (
    <DecksView
      decks={decksInFolder}
      onCreateDeck={onCreateDeck}
      categoriesById={categoriesById}
      currentCategory={currentCategory}
    />
  );
}

export default Decks;
