import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';
import type IAuthResponse from './interfaces/IAuthResponse';

export const initialState: IAuthResponse = {
  accessToken: undefined,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    removeTokens: () => initialState,
    saveAccessToken: (state, action: PayloadAction<string | undefined>) => {
      state.accessToken = action.payload;
    },
  },
});

export const { removeTokens, saveAccessToken } = authSlice.actions;

export default authSlice.reducer;
