import RequestUrl from '../../../../constants/RequestUrl';
import mainApi from '../../../apis/mainApi';

import type IConvertReq from '../interfaces/IConvertReq';
import type IConvertRes from '../interfaces/IConvertRes';

const mainConvertApi = mainApi.injectEndpoints({
  endpoints: (build) => ({
    convert: build.mutation<IConvertRes, IConvertReq>({
      query: (body) => ({
        url: RequestUrl.convertTextToCards(),
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useConvertMutation,
} = mainConvertApi;

export default mainConvertApi;
