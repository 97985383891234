import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import AddCardSection from '../AddCardSection/AddCardSection';
import CardSection from '../CardSection/CardSection';
import DeckSection from '../DeckSection/DeckSection';

import type ICreateDeckForm from './interfaces/CreateDeckForm';

function CreateDeckForm({
  control,
  cards,
  categories,
  onAddCard,
  onRemoveCard,
  onSubmit,
  onConvertText,
  isEdit,
}: ICreateDeckForm) {
  const { t } = useTranslation();

  const [isFixedSaveButton, setIsFixedSaveButton] = useState(false);
  useEffect(() => {
    setIsFixedSaveButton(document.body.clientHeight > window.innerHeight);
  }, [cards]);

  return (
    <Stack spacing={3}>
      <DeckSection
        control={control}
        categories={categories}
        isEdit={isEdit}
        onConvertText={onConvertText}
      />
      <AddCardSection
        onAddCard={onAddCard}
      />
      <Stack spacing={3} direction="column-reverse">
        {cards.map((item, index) => (
          <CardSection
            key={`card_${item.id}`}
            control={control}
            cardIndex={index}
            cardsCount={cards.length}
            onRemoveCard={onRemoveCard}
          />
        ))}
      </Stack>
      <Stack
        direction="row"
        justifyContent="end"
        sx={isFixedSaveButton ? {
          position: 'fixed',
          bottom: 0,
          right: 0,
          pb: 2,
          pr: 3,
        } : undefined}
      >
        <Button
          variant="contained"
          sx={{ width: 118 }}
          onClick={onSubmit}
        >
          {t('decks.saveDeck')}
        </Button>
      </Stack>
    </Stack>
  );
}

export default CreateDeckForm;
