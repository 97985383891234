import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import ActivityBar from '../../components/ActivityBar/ActivityBar';
import Banner from '../../components/Banner/Banner';
import CreateDeck from '../../components/CreateDeck/CreateDeck';
import CreateFolder from '../../components/CreateFolder/CreateFolder';
import Deck from '../../components/Deck/Deck';
import Folder from '../../components/Folder/Folder';
import Panel from '../../components/Panel/Panel';
import SwiperNavButtons from '../../components/SwiperNavButtons/SwiperNavButtons';

import type { ReactElement } from 'react';
import type IDashboard from './interfaces/IDashboard';

import styles from './sass/DashboardView.module.scss';

function DashboardView({
  decks, categories, onCreateFolder, onCreateDeck, motivationStatus,
}: IDashboard): ReactElement {
  const { t } = useTranslation();

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Banner
              title={t(`home.motivationBanner.${motivationStatus}.title`)}
              text={t(`home.motivationBanner.${motivationStatus}.text`)}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <ActivityBar />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Panel title={t('home.latestActions')}>
          <Swiper
            className={styles.swiper}
            slidesPerView="auto"
            spaceBetween={16}
            modules={[Navigation]}
            navigation
          >
            {decks?.length ? (decks?.map(({
              deckID, deckName, flashcardItems, category,
            }) => (
              <SwiperSlide className={styles.swiperSlide} key={deckID}>
                <Deck
                  countOfCards={flashcardItems?.length || 0}
                  title={deckName}
                  folder={category}
                  deckID={deckID}
                />
              </SwiperSlide>
            ))) : <CreateDeck onCreate={onCreateDeck} /> }
            <SwiperNavButtons isAbsolute />
          </Swiper>
        </Panel>
      </Grid>

      <Grid item xs={12}>
        <Panel title="Popular folders">
          <Swiper
            className={styles.swiper}
            slidesPerView="auto"
            spaceBetween={16}
            modules={[Navigation]}
            navigation
          >
            {categories?.length ? categories?.map(({
              categoryID, categoryName, colorCode, cardsCount, decksCount,
            }) => (
              <SwiperSlide className={styles.swiperSlide} key={categoryID}>
                <Folder
                  categoryId={categoryID}
                  color={colorCode}
                  title={categoryName}
                  cardsCount={cardsCount || 0}
                  decksCount={decksCount || 0}
                />
              </SwiperSlide>
            )) : <CreateFolder onCreate={onCreateFolder} />}
            <SwiperNavButtons isAbsolute />
          </Swiper>
        </Panel>
      </Grid>
    </Grid>
  );
}

export default DashboardView;
