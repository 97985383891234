import { type ReactElement } from 'react';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack';

import ProgressModal from '../../components/ProgressModal/ProgressModal';
import CardsSection from './components/CardsSection/CardsSection';
import StartSection from './components/StartSection/StartSection';

import type IStudyView from './interfaces/IStudyView';

function StudyView({
  deck,
  answers,
  showModal,
  showCards,
  correctAnswers,
  onSessionStart,
  onSelectAnswer,
  onConfirm,
  onClose,
  isLoadingDeck,
}: IStudyView): ReactElement {
  return (
    <Stack flexGrow={1}>
      {showCards ? (
        <Fade in={showCards}>
          <Box>
            <CardsSection
              deck={deck}
              answers={answers}
              onSelectAnswer={onSelectAnswer}
            />
          </Box>
        </Fade>
      ) : (
        <StartSection
          deckName={deck?.deckName}
          onSessionStart={onSessionStart}
          isLoadingDeck={isLoadingDeck}
        />
      )}
      <ProgressModal
        open={showModal}
        percentage={correctAnswers}
        onConfirm={onConfirm}
        onClose={onClose}
      />
    </Stack>
  );
}

export default StudyView;
