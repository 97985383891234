import styles from './sass/Contact.module.scss';

function Contact() {
    return (
      <section className={styles.contact}>
        <div className={styles.container}>
            <div className={styles.fourth}>
                <div className={styles.row}>
                    <div className={styles.head}>
                    <h2>
                        Contact Us
                    </h2>
                    </div>
                    <p>
                        We at vFlash, LLC are here to assist you and answer any questions you may have about our services.
                        Your feedback is invaluable to us as we strive to provide the best possible experience for our users.
                    </p>
                    <p>
                        For general inquiries, support, feedback, or to suggest new features - email us at: <a href="mailto:support@vflash.ai"> support@vflash.ai</a>
                    </p>
                </div>
            </div>
        </div>
      </section>
    );
  }
  
  export default Contact;