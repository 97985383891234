import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

const CreateDeckRoot = styled(
  Stack,
)(({ theme }) => ({
  maxWidth: 247,
  padding: theme.spacing(3),
  backgroundColor: theme.palette.primary.contrastText,
  borderRadius: theme.spacing(1.5),
  transition: theme.transitions.create('filter'),
  '&:hover': {
    filter: 'drop-shadow(0px 4px 6px rgb(229, 230, 232)) drop-shadow(0px 12px 16px rgb(242, 243, 244))',
  },
}));

export default CreateDeckRoot;
