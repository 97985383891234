import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';

import CreateDeck from '../../components/CreateDeck/CreateDeck';
import Deck from '../../components/Deck/Deck';
import Panel from '../../components/Panel/Panel';

import type { ReactElement } from 'react';
import type IDecks from './interfaces/IDecks';

function DecksView({
  decks, onCreateDeck, categoriesById, currentCategory,
}: IDecks): ReactElement {
  const { t } = useTranslation();

  return (
    <Panel title={currentCategory?.categoryName || t('decks.decksTitle')}>
      {decks?.length ? (
        <Grid container spacing={2}>
          {decks.map(({
            categoryID, deckID, deckName, flashcardItems,
          }) => (
            <Grid item key={deckID}>
              <Deck
                countOfCards={flashcardItems?.length}
                title={deckName}
                deckID={deckID}
                folder={categoriesById?.[categoryID]}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <CreateDeck onCreate={onCreateDeck} />
      )}
    </Panel>
  );
}

export default DecksView;
