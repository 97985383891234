import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import Panel from '../../../../components/Panel/Panel';
import CardSectionHead from './CardSectionHead';

import type ICardSection from './interfaces/ICardSection';

const buttonStyles = {
  minWidth: 24,
  height: 24,
  padding: 'unset',
  borderRadius: '4px',
};

function CardSection({
  control,
  cardIndex,
  cardsCount,
  onRemoveCard,
}: ICardSection) {
  const { t } = useTranslation();

  const onHandleRemove = () => {
    if (cardsCount > 1) {
      onRemoveCard(cardIndex);
    }
  };

  return (
    <Panel>
      <CardSectionHead>
        <Stack
          mb={1.5}
          gap={2}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography fontWeight={600} lineHeight="normal" color="primary.light">
            {cardIndex + 1}
          </Typography>
          {cardsCount > 1 ? (
            <Button
              variant="outlined"
              sx={buttonStyles}
              aria-label="delete item"
              onClick={onHandleRemove}
            >
              <DeleteRoundedIcon
                sx={(theme) => ({
                  fontSize: 14,
                  color: theme.palette.primary.light,
                })}
              />
            </Button>
          ) : null}
        </Stack>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Typography mb={1} variant="body2" fontWeight={600} color="text.primary">
              {t('decks.frontSide')}
            </Typography>
            <Controller
              name={`flashcardItems.${cardIndex}.side1`}
              control={control}
              render={({
                field,
                fieldState: { error: fieldError },
              }) => (
                <FormControl error={!!fieldError} fullWidth>
                  <TextField
                    helperText={fieldError?.message}
                    error={!!fieldError}
                    placeholder={t('decks.cardInputHint')}
                    fullWidth
                    {...field}
                  />
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography mb={1} fontSize={14} fontWeight={600} color="text.primary">
              {t('decks.backSide')}
            </Typography>
            <Controller
              name={`flashcardItems.${cardIndex}.side2`}
              control={control}
              render={({
                field,
                fieldState: { error: fieldError },
              }) => (
                <FormControl error={!!fieldError} fullWidth>
                  <TextField
                    helperText={fieldError?.message}
                    error={!!fieldError}
                    placeholder={t('decks.cardInputHint')}
                    fullWidth
                    {...field}
                  />
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
      </CardSectionHead>
    </Panel>
  );
}

export default CardSection;
