import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'notistack';

import { INTERNAL_SERVER_ERROR } from '../../../constants/StatusCodes';
import i18n from '../../../i18n';
import mainCategoryApi from '../category/apis/mainCategoryApi';
import mainConvertApi from '../convert/apis/mainConvertApi';
import mainDeckApi from '../deck/apis/mainDeckApi';
import mainSearchApi from '../search/apis/mainSearchApi';
import mainStudyApi from '../study/apis/mainStudyApi';

import type IWarningState from './interfaces/IWarningState';

export const initialState: IWarningState = {
};

const warningSlice = createSlice({
  name: 'warning',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(isAnyOf(
        mainCategoryApi.endpoints.createCategory.matchRejected,
        mainCategoryApi.endpoints.updateCategory.matchRejected,
        mainCategoryApi.endpoints.deleteCategory.matchRejected,
        mainDeckApi.endpoints.oneDeck.matchRejected,
        mainDeckApi.endpoints.createDeck.matchRejected,
        mainDeckApi.endpoints.updateDeck.matchRejected,
        mainDeckApi.endpoints.deleteDeck.matchRejected,
        mainConvertApi.endpoints.convert.matchRejected,
      ), (state, { payload }) => {
        enqueueSnackbar(payload?.data?.error || payload?.data?.detail || payload?.data?.title
          || i18n.t('common.somethingWentWrong'), { variant: 'warning' });
      })
      .addMatcher(isAnyOf(
        mainCategoryApi.endpoints.categories.matchRejected,
        mainDeckApi.endpoints.decks.matchRejected,
        mainDeckApi.endpoints.oneDeck.matchRejected,
        mainSearchApi.endpoints.search.matchRejected,
        mainStudyApi.endpoints.activities.matchRejected,
      ), (state, { payload }) => {
        if (payload?.status && payload?.status >= INTERNAL_SERVER_ERROR) {
          enqueueSnackbar(payload?.data?.error || payload?.data?.detail || payload?.data?.title
            || i18n.t('common.somethingWentWrong'), { variant: 'error' });
        }
      });
  },
});

export default warningSlice.reducer;
