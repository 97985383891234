import { useTranslation } from 'react-i18next';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import Panel from '../../../../components/Panel/Panel';

import type IAddCardSection from './interfaces/IAddCardSection';

import styles from './sass/AddCardSection.module.scss';

const buttonStyles = {
  minWidth: 'auto',
  padding: '6px 16px',
  alignSelf: 'center',
  backgroundColor: 'white',
  fontSize: 12,
  '& .MuiSvgIcon-root': {
    fontSize: 'inherit',
  },
};

function AddCardSection({
  onAddCard,
}: IAddCardSection) {
  const { t } = useTranslation();

  return (
    <Panel className={styles.root}>
      <Stack>
        <Button
          sx={buttonStyles}
          variant="outlined"
          startIcon={<AddRoundedIcon />}
          onClick={onAddCard}
        >
          {t('decks.addCard')}
        </Button>
      </Stack>
    </Panel>
  );
}

export default AddCardSection;
