import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import PaperRoot from './PanelRoot';

import type { ReactElement } from 'react';
import type IPanel from './interfaces/IPanel';

function Panel({
  className,
  title,
  children,
}: IPanel): ReactElement {
  return (
    <PaperRoot
      elevation={0}
      className={className}
    >
      {title ? (
        <Typography
          variant="h6"
          component="h2"
          fontWeight={600}
          mb={3}
          pr={9.5}
          lineHeight="normal"
        >
          {title}
        </Typography>
      ) : null}
      <Box>
        {children}
      </Box>
    </PaperRoot>
  );
}

export default Panel;
