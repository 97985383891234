import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ProgressBarRoot from '../../../../components/AIModal/ProgressBarRoot';

import type { Theme } from '@mui/material/styles';
import type IStudyProgress from './interfaces/IStudyProgress';

const countStyles = (theme: Theme) => ({
  padding: '2px 8px',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  borderRadius: theme.spacing(3),
});

function StudyProgress({
  length,
  currentIndex,
}: IStudyProgress) {
  return (
    <Stack
      spacing={1.5}
      alignItems="center"
    >
      <Typography
        variant="body2"
        fontSize={12}
        fontWeight={600}
        lineHeight="160%"
        sx={countStyles}
      >
        {`${currentIndex + 1}/${length}`}
      </Typography>
      <Box
        sx={{
          width: '100%',
          borderRadius: 8,
        }}
      >
        <ProgressBarRoot
          value={Math.round(((currentIndex + 1) * 100) / length)}
          variant="determinate"
        />
      </Box>
    </Stack>
  );
}

export default StudyProgress;
