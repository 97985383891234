import Menu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';

const Dropdown = styled(
  Menu,
)(({ theme }) => ({
  '& .MuiPaper-root': {
    minWidth: 240,
    borderRadius: theme.spacing(1),
    borderColor: theme.palette.grey[400],
    borderWidth: 1,
    '& .MuiMenu-list': {
      padding: theme.spacing(1),
    },
    '& .MuiMenuItem-root': {
      padding: '10px 16px',
      fontSize: 14,
      color: theme.palette.grey[900],
      borderColor: 'transparent',
      borderRadius: 4,
      transition: theme.transitions.create(['background-color', 'border-color']),
      '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(1.5),
        fontSize: 20,
        color: theme.palette.grey[700],
      },
      '&:hover': {
        borderColor: theme.palette.grey[400],
        backgroundColor: theme.palette.grey[200],
      },
    },
  },
}));

export default Dropdown;
