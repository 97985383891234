import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';

import type ICardHover from './interfaces/ICardHover';

const CardHover = styled(
  Card,
)<ICardHover>(({ theme, shadow = 1 }) => ({
  ':hover': {
    boxShadow: theme.shadows[shadow],
  },
}));

export default CardHover;
