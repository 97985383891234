import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import type IStartSection from './interfaces/IStartSection';

function StartSection({
  deckName,
  onSessionStart,
  isLoadingDeck,
}: IStartSection) {
  const { t } = useTranslation();

  return (
    <Stack flexGrow={1}>
      <Typography>
        {t('study.studyTitle', {
          deckName,
        })}
      </Typography>
      <Stack flexGrow={1}>
        <Stack
          spacing={5}
          flexGrow={1}
          justifyContent="center"
        >
          <Typography
            fontSize={20}
            fontWeight={600}
            textAlign="center"
          >
            {deckName}
          </Typography>
          <LoadingButton
            variant="contained"
            sx={{
              minWidth: 118,
              alignSelf: 'center',
            }}
            onClick={onSessionStart}
            loading={isLoadingDeck}
            disabled={isLoadingDeck}
          >
            {t('common.start')}
          </LoadingButton>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default StartSection;
