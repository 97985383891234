// import Layout from '../../componets/Layout';

import styles from './sass/Privacy.module.scss';

function Privacy() {
  return (
    <section className={styles.privacy}>
      <div className="container">
        <div className={styles.row}>
          <div className={styles.head}>
            <h2>
              Privacy Policy Overview
            </h2>
          </div>
          <p>
            We value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your data when you interact with our services. By accessing or using our platform, you agree to this policy. Please read it carefully.
          </p>
        </div>
        <div className={styles.row}>
          <div className={styles.head}>
            <h2>
              Information We Collect
            </h2>
          </div>
          <p>
            When you use our platform, we may collect the following types of information:
          </p>
          <ul className="dot-list">
            <li>
              <strong>Personal Information:</strong> This includes your name, email address, phone number, payment information, and other details you provide when registering, making a purchase, or contacting us.
            </li>
            <li>
              <strong>Usage Data:</strong> Information about how you interact with our platform, such as IP address, browser type, operating system, pages viewed, and timestamps.
            </li>
            <li>
              <strong>Cookies and Tracking Technologies:</strong> Data collected using cookies, web beacons, and similar technologies to enhance your experience and improve our services.
            </li>
            <li>
              <strong>Third-Party Data:</strong> Information received from partners, such as payment processors or advertising platforms.
            </li>
          </ul>
        </div>
        <div className={styles.row}>
          <div className={styles.head}>
            <h2>
              How We Use Your Information
            </h2>
          </div>
          <p>
            We use the information we collect for various purposes, including:
          </p>
          <ul className="dot-list">
            <li>To operate and maintain our platform.</li>
            <li>To process transactions and send confirmations.</li>
            <li>To provide customer support and respond to inquiries.</li>
            <li>To analyze usage patterns and improve functionality.</li>
            <li>To send updates, promotions, or other communications.</li>
            <li>To comply with legal obligations and enforce our terms.</li>
          </ul>
        </div>
        <div className={styles.row}>
          <div className={styles.head}>
            <h2>
              Sharing Your Information
            </h2>
          </div>
          <p>
            We may share your information under the following circumstances:
          </p>
          <ul className="dot-list">
            <li>
              <strong>Service Providers:</strong> Third-party vendors who assist in providing services such as payment processing, hosting, or analytics.
            </li>
            <li>
              <strong>Legal Requirements:</strong> If required to comply with applicable laws, regulations, or valid legal processes.
            </li>
            <li>
              <strong>Business Transfers:</strong> In connection with mergers, acquisitions, or sales of assets.
            </li>
            <li>
              <strong>With Your Consent:</strong> When you explicitly authorize us to do so.
            </li>
          </ul>
        </div>
        <div className={styles.row}>
          <div className={styles.head}>
            <h2>
              Data Retention
            </h2>
          </div>
          <p>
            We retain your personal data for as long as necessary to fulfill the purposes outlined in this policy or comply with legal obligations. When data is no longer needed, we securely delete or anonymize it.
          </p>
        </div>
        <div className={styles.row}>
          <div className={styles.head}>
            <h2>
              Data Security
            </h2>
          </div>
          <p>
            We implement robust security measures to protect your data against unauthorized access, alteration, or destruction. However, no system is completely secure, and we cannot guarantee absolute protection.
          </p>
        </div>
        <div className={styles.row}>
          <div className={styles.head}>
            <h2>
              Your Privacy Rights
            </h2>
          </div>
          <ul className="dot-list">
            <li>Access and obtain a copy of your personal data.</li>
            <li>Request corrections or updates to your information.</li>
            <li>Request deletion of your data, subject to legal obligations.</li>
            <li>Opt out of certain data processing activities.</li>
            <li>File a complaint with a regulatory authority.</li>
          </ul>
        </div>
        <div className={styles.row}>
          <div className={styles.head}>
            <h2>
              Cookies and Tracking Technologies
            </h2>
          </div>
          <p>
            We use cookies and similar technologies to enhance your experience. You can manage cookie preferences through your browser settings. Disabling cookies may affect some functionality of our platform.
          </p>
        </div>
        <div className={styles.row}>
          <div className={styles.head}>
            <h2>
              Children’s Privacy
            </h2>
          </div>
          <p>
            Our services are not intended for children under 13 years old. We do not knowingly collect data from children. If we discover that a child has provided personal data, we will delete it promptly.
          </p>
        </div>
        <div className={styles.row}>
          <div className={styles.head}>
            <h2>
              Changes to This Policy
            </h2>
          </div>
          <p>
            We may update this Privacy Policy from time to time. Changes will be posted on this page, and we encourage you to review it periodically. Significant changes will be communicated through your account or email.
          </p>
        </div>
      </div>
    </section>

  );
}

export default Privacy;
