import Typography from '@mui/material/Typography';

import BannerRoot from './BannerRoot';

import type IBanner from './interfaces/IBanner';

function Banner({
  title,
  text,
}: IBanner) {
  return (
    <BannerRoot spacing={2}>
      <Typography
        variant="h2"
        fontSize={32}
        fontWeight={600}
      >
        {title}
      </Typography>
      <Typography lineHeight="140%">
        {text}
      </Typography>
    </BannerRoot>
  );
}

export default Banner;
