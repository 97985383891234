import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';

import type { DialogProps } from '@mui/material/Dialog';

const ModalRoot = styled(Dialog)<DialogProps>(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.spacing(1),
  },
}));

export default ModalRoot;
