import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import type { StackProps } from '@mui/material/Stack';

import StarIcon from '../../assets/images/star.svg';

const BannerRoot = styled(
  Stack,
)<StackProps>(({ theme }) => ({
  height: '100%',
  padding: `${theme.spacing(5)} ${theme.spacing(3)}`,
  paddingRight: 182,
  backgroundColor: theme.palette.primary.main,
  backgroundImage: `url(${StarIcon}), url(${StarIcon}), url(${StarIcon})`,
  backgroundPosition: 'right 26px top 14px, right 14px top 128px, right 78px top 128px',
  backgroundSize: '103px, 22px, 60px',
  backgroundRepeat: 'no-repeat',
  color: theme.palette.primary.contrastText,
  borderRadius: theme.spacing(3),
}));

export default BannerRoot;
