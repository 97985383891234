import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import type { BoxProps } from '@mui/material/Box';

const DeckSectionHead = styled(
  Box,
)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.palette.primary.contrastText,
  borderRadius: theme.spacing(1.5),
}));

export default DeckSectionHead;
