import { useEffect, useState } from 'react';

import Navbar from '../../pages/Landing/components/Navbar';

import styles from '../../pages/Landing/sass/Header.module.scss';

function Header() {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header
      id="header"
      className={`${styles.header} ${
        scrollPosition > 100 ? styles.hidden : ''
      }`}
    >
      <div className="container">
        <Navbar />
      </div>
    </header>
  );
}

export default Header;
