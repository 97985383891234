import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

import AppRoutes from '../../constants/AppRoutes';
import { testIdLoginLoader } from '../../constants/TestId';
import { useAppDispatch, useAppSelector } from '../../store/hooks/useApp';
import selectUserToken from '../../store/slices/auth/selectors';
import { saveAccessToken } from '../../store/slices/auth/slice';
import Page from './Page';

import type { ReactElement } from 'react';
import type IPageProtected from './interfaces/IPageProtected';

/**
 * Protected page layout
 */
function PageProtected({ layout = <Page /> }: IPageProtected): ReactElement {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const savedToken = useAppSelector(selectUserToken);

  useEffect(() => {
    const getAccessToken = async () => {
      const accessToken = await getAccessTokenSilently();
      dispatch(saveAccessToken(accessToken));
    };

    if (isAuthenticated) {
      getAccessToken();
    } else {
      dispatch(saveAccessToken(undefined));
    }
  }, [dispatch, getAccessTokenSilently, isLoading, isAuthenticated]);

  useEffect(() => {
    if (!savedToken && !isLoading) {
      navigate(AppRoutes.login);
    }
  }, [savedToken, isLoading, navigate]);

  return savedToken ? layout : <div data-testid={testIdLoginLoader} />;
}

export default withAuthenticationRequired(PageProtected);
