import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '../../types/TStore';
import type IStudySlice from './interfaces/IStudySlice';

const selectStudy = (state: RootState): IStudySlice => state.study;

const selectAnswers = createSelector(selectStudy, (study) => study.answers);

export default selectAnswers;
