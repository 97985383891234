import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '../../types/TStore';
import type IAuthResponse from './interfaces/IAuthResponse';

const selectAuth = (state: RootState): IAuthResponse => state.auth;

const selectUserToken = createSelector(selectAuth, (auth) => auth.accessToken);

export default selectUserToken;
