import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

const CreateFolderRoot = styled(
  Stack,
)(({ theme }) => ({
  minHeight: 154,
  padding: '31px 16px 16px',
  backgroundColor: theme.palette.primary.contrastText,
  clipPath: `polygon(12.255% 100%, 87.745% 100%, 87.745% 100%,
    88.969% 99.999%, 90.074% 99.992%, 91.071% 99.973%, 91.968% 99.936%,
    92.776% 99.876%, 93.504% 99.785%, 94.162% 99.659%, 94.761% 99.491%,
    95.309% 99.276%, 95.818% 99.006%, 95.818% 99.006%, 96.242% 98.729%,
    96.649% 98.422%, 97.038% 98.085%, 97.407% 97.721%, 97.757% 97.331%,
    98.085% 96.915%, 98.391% 96.475%, 98.673% 96.013%, 98.932% 95.529%,
    99.165% 95.024%, 99.165% 95.024%, 99.391% 94.419%, 99.573% 93.766%,
    99.714% 93.054%, 99.82% 92.27%, 99.896% 91.404%, 99.947% 90.443%,
    99.977% 89.375%, 99.993% 88.19%, 99.999% 86.874%, 100% 85.418%,
    100% 23.949%, 100% 23.949%, 99.999% 22.493%, 99.993% 21.177%,
    99.977% 19.992%, 99.947% 18.924%, 99.896% 17.963%, 99.82% 17.097%,
    99.714% 16.313%, 99.573% 15.601%, 99.391% 14.948%, 99.165% 14.343%,
    99.165% 14.343%, 98.932% 13.839%, 98.673% 13.355%, 98.391% 12.892%,
    98.085% 12.452%, 97.757% 12.037%, 97.407% 11.646%, 97.038% 11.282%,
    96.649% 10.946%, 96.242% 10.638%, 95.818% 10.36%, 95.818% 10.36%,
    95.309% 10.091%, 94.761% 9.876%, 94.162% 9.708%, 93.504% 9.582%,
    92.776% 9.491%, 91.968% 9.431%, 91.071% 9.394%, 90.074% 9.375%,
    88.969% 9.368%, 87.745% 9.367%, 46.33% 9.367%, 46.33% 9.367%,
    45.625% 9.357%, 44.886% 9.32%, 44.118% 9.242%, 43.323% 9.112%,
    42.507% 8.916%, 41.673% 8.643%, 40.826% 8.279%, 39.969% 7.812%,
    39.107% 7.229%, 38.245% 6.519%, 38.245% 6.519%, 37.532% 5.875%,
    37.073% 5.461%, 36.804% 5.217%, 36.66% 5.086%, 36.576% 5.008%,
    36.487% 4.924%, 36.328% 4.776%, 36.035% 4.504%, 35.543% 4.05%,
    34.787% 3.354%, 34.787% 3.354%, 33.92% 2.584%, 33.126% 1.936%,
    32.39% 1.402%, 31.696% 0.971%, 31.029% 0.633%, 30.374% 0.379%,
    29.714% 0.198%, 29.034% 0.082%, 28.319% 0.019%, 27.553% 0%,
    12.255% 0%, 12.255% 0%, 11.031% 0.001%, 9.926% 0.008%, 8.929% 0.027%,
    8.032% 0.064%, 7.225% 0.124%, 6.496% 0.215%, 5.838% 0.341%, 5.239% 0.509%,
    4.691% 0.724%, 4.182% 0.993%, 4.182% 0.993%, 3.758% 1.271%, 3.351% 1.578%,
    2.962% 1.915%, 2.593% 2.279%, 2.243% 2.669%, 1.915% 3.085%, 1.609% 3.525%,
    1.327% 3.987%, 1.068% 4.472%, 0.835% 4.976%, 0.835% 4.976%, 0.609% 5.581%,
    0.427% 6.234%, 0.286% 6.946%, 0.18% 7.73%, 0.104% 8.596%, 0.053% 9.557%,
    0.023% 10.625%, 0.007% 11.81%, 0.001% 13.126%, 0% 14.582%, 0% 85.418%,
    0% 85.418%, 0.001% 86.874%, 0.007% 88.19%, 0.023% 89.375%, 0.053% 90.443%,
    0.104% 91.404%, 0.18% 92.27%, 0.286% 93.054%, 0.427% 93.766%, 0.609% 94.419%,
    0.835% 95.024%, 0.835% 95.024%, 1.068% 95.529%, 1.327% 96.013%, 1.609% 96.475%,
    1.915% 96.915%, 2.243% 97.331%, 2.593% 97.721%, 2.962% 98.085%, 3.351% 98.422%,
    3.758% 98.729%, 4.182% 99.006%, 4.182% 99.006%, 4.691% 99.276%, 5.239% 99.491%,
    5.838% 99.659%, 6.496% 99.785%, 7.225% 99.876%, 8.032% 99.936%, 8.929% 99.973%,
    9.926% 99.992%, 11.031% 99.999%, 12.255% 100%)`,
}));

export default CreateFolderRoot;
