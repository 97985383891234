import { styled } from '@mui/material/styles';

import Modal from '../Modal/Modal';

import type IModal from '../Modal/interfaces/IModal';

const AIModalRoot = styled(Modal)<IModal>(({ theme }) => ({
  '.MuiDialogContent-root': {
    background: theme.palette.secondary.main,
  },
}));

export default AIModalRoot;
