import { useEffect, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { skipToken } from '@reduxjs/toolkit/query';

import AppRoutes from '../../constants/AppRoutes';
import usePageLoader from '../../hooks/usePageLoader';
import { useCategoriesQuery } from '../../store/slices/category/apis/mainCategoryApi';
import { useCreateDeckMutation, useOneDeckQuery, useUpdateDeckMutation } from '../../store/slices/deck/apis/mainDeckApi';
import { createDeckSchema } from '../../utils/validators';
import DecksCreateView from './DecksCreateView';

import type { ReactElement } from 'react';
import type IFlashcard from '../../store/slices/deck/interfaces/IFlashcard';
import type IDeckCreateFields from './interfaces/IDeckCreateFields';

function DecksCreate(): ReactElement {
  const navigate = useNavigate();
  const { deckId } = useParams();
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);

  const { data: categories } = useCategoriesQuery();
  const { currentData: deck, isLoading: isLoadingDeck } = useOneDeckQuery(deckId ? +deckId : skipToken);
  const [createDeck] = useCreateDeckMutation();
  const [updateDeck] = useUpdateDeckMutation();

  usePageLoader(isLoadingDeck);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
  } = useForm<IDeckCreateFields>({
    mode: 'onBlur',
    resolver: yupResolver<IDeckCreateFields>(createDeckSchema),
    defaultValues: {
      deckName: '',
      categoryID: query.get('categoryId') || '',
      flashcardItems: [{
        side1: '',
        side2: '',
      }],
    },
  });

  const {
    fields: cards,
    append: onAppendCardsByForm,
    remove: onRemoveCardsByForm,
  } = useFieldArray({ control, name: 'flashcardItems' });

  const onAddOneCard = () => onAppendCardsByForm({
    side1: '',
    side2: '',
  });

  const onHandleCreateSubmit = handleSubmit(async (values) => {
    let response;
    if (deckId) {
      response = await updateDeck({
        ...values,
        categoryID: +values.categoryID,
        deckID: +deckId,
      });
    } else {
      response = await createDeck({
        ...values,
        categoryID: +values.categoryID,
      });
    }

    if (!('error' in response)) {
      reset();
      navigate(AppRoutes.decks);
    }
  });

  useEffect(() => {
    if (deckId && deck) {
      setValue('deckName', deck.deckName);
      setValue('categoryID', String(deck.categoryID));
      deck.flashcardItems.forEach(item => onAppendCardsByForm(item));
    }
  }, [deckId, deck]);
  

  const onConvertText = (flashcardItems: IFlashcard[]) => {
    setValue('flashcardItems', flashcardItems);
  };

  return (
    <DecksCreateView
      control={control}
      cards={cards}
      categories={categories || []}
      onAddCard={onAddOneCard}
      onRemoveCard={onRemoveCardsByForm}
      onSubmit={onHandleCreateSubmit}
      isEdit={!!deckId}
      onConvertText={onConvertText}
    />
  );
}

export default DecksCreate;
