import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ProgressBarRoot from './ProgressBarRoot';

import type IAIProgress from './interfaces/IAIProgress';

import { ReactComponent as AlgorithmIcon } from '../../assets/images/algorithm.svg';

function AIProgress({
  progress,
}: IAIProgress) {
  const { t } = useTranslation();

  return (
    <Stack pt={8} pb={8} alignItems="center">
      <Stack
        alignItems="center"
        justifyContent="center"
        direction="column"
        spacing={2}
        sx={{ maxWidth: 300 }}
      >
        <AlgorithmIcon />
        <Typography
          variant="body2"
          textAlign="center"
          color="text.secondary"
          sx={{ maxWidth: 269 }}
        >
          {t('modal.aiLoadingModalHint')}
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ width: '100%' }}
        >
          <Box
            sx={{
              width: '100%',
              mr: 1,
              borderRadius: 8,
            }}
          >
            <ProgressBarRoot value={progress} variant="determinate" />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2" color="primary.main">
              {`${Math.round(progress)}%`}
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default AIProgress;
