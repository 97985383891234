// import Layout from '../../components/Layout';

import styles from './sass/Terms.module.scss';

function Terms() {
  return (
    <section className={styles.terms}>
      <div className="container">
        <div className={styles.row}>
          <div className={styles.head}>
            <h2>Terms and Conditions</h2>
          </div>
          <p>
            Welcome to our platform. By using our services, you agree to comply with and be bound by the following terms and conditions. Please read them carefully before using our website or services. If you do not agree to these terms, you may not use our platform.
          </p>
        </div>
        <div className={styles.row}>
          <div className={styles.head}>
            <h2>Use of Our Services</h2>
          </div>
          <p>
            You agree to use our services only for lawful purposes and in accordance with these terms. You are prohibited from using our platform in any way that could damage, disable, overburden, or impair it, or interfere with any other user's enjoyment of our services. 
          </p>
        </div>
        <div className={styles.row}>
          <div className={styles.head}>
            <h2>Account Responsibilities</h2>
          </div>
          <p>
            You are responsible for maintaining the confidentiality of your account information, including your password, and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account or any other breach of security.
          </p>
        </div>
        <div className={styles.row}>
          <div className={styles.head}>
            <h2>Intellectual Property</h2>
          </div>
          <p>
            All content, features, and functionality on this platform, including but not limited to text, graphics, logos, and software, are owned by us or our licensors and are protected by copyright, trademark, and other intellectual property laws. You may not reproduce, distribute, or otherwise use our content without prior written consent.
          </p>
        </div>
        <div className={styles.row}>
          <div className={styles.head}>
            <h2>Termination</h2>
          </div>
          <p>
            We reserve the right to terminate or suspend your access to our platform at any time, without prior notice, for conduct that we believe violates these terms or is otherwise harmful to other users, us, or third parties.
          </p>
        </div>
        <div className={styles.row}>
          <div className={styles.head}>
            <h2>Limitation of Liability</h2>
          </div>
          <p>
            We shall not be held liable for any damages arising from your use of our platform, including but not limited to direct, indirect, incidental, punitive, or consequential damages. Your use of the platform is at your sole risk.
          </p>
        </div>
        <div className={styles.row}>
          <div className={styles.head}>
            <h2>Changes to These Terms</h2>
          </div>
          <p>
            We may update these terms from time to time. Any changes will be posted on this page, and your continued use of the platform constitutes your acceptance of the updated terms.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Terms;