import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import AppRoutes from '../../constants/AppRoutes';
import { testIdLoginLoader } from '../../constants/TestId';
import { useAppSelector } from '../../store/hooks/useApp';
import selectUserToken from '../../store/slices/auth/selectors';

function Login() {
  const { isLoading, loginWithRedirect } = useAuth0();
  const savedToken = useAppSelector(selectUserToken);

  useEffect(() => {
    if (!savedToken && !isLoading) {
      loginWithRedirect();
    }
  }, [isLoading, loginWithRedirect, savedToken]);

  return savedToken
    ? <Navigate to={AppRoutes.home} />
    : <div data-testid={testIdLoginLoader} />;
}

export default Login;
