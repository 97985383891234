import Box from '@mui/material/Box';

import CreateDeckForm from './components/CreateDeckForm/CreateDeckForm';

import type { ReactElement } from 'react';
import type IDecksCreateView from './interfaces/IDecksCreateView';

function DecksCreateView({
  control,
  cards,
  categories,
  onAddCard,
  onRemoveCard,
  onSubmit,
  isEdit,
  onConvertText,
}: IDecksCreateView): ReactElement {
  return (
    <Box>
      <CreateDeckForm
        control={control}
        cards={cards}
        categories={categories}
        onAddCard={onAddCard}
        onRemoveCard={onRemoveCard}
        onSubmit={onSubmit}
        isEdit={isEdit}
        onConvertText={onConvertText}
      />
    </Box>
  );
}

export default DecksCreateView;
