import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { skipToken } from '@reduxjs/toolkit/query';

import { FOLDER_COLORS } from '../../constants/Common';
import {
  useCategoriesQuery,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
} from '../../store/slices/category/apis/mainCategoryApi';
import { createFolderSchema } from '../../utils/validators';
import Modal from '../Modal/Modal';
import ColorRadio from './RadioColor';

import type ICreateFolderFields from './interfaces/ICreateFolderFields';
import type ICreateFolderModal from './interfaces/ICreateFolderModal';

const labelStyles = {
  ml: 0,
  mr: 1.5,
  '& .MuiTypography-root': {
    width: 0,
    height: 0,
    visibility: 'hidden',
  },
};

const iconStyles = {
  fontSize: 16,
  color: 'white',
};

function CreateFolderModal({
  open,
  onClose,
  categoryId,
  onSave,
}: ICreateFolderModal) {
  const { t } = useTranslation();

  const isEdit = !!categoryId;

  const { data: categories } = useCategoriesQuery(categoryId ? undefined : skipToken);

  const category = useMemo(
    () => categories?.find(({ categoryID }) => categoryID === categoryId),
    [categories, categoryId],
  );

  const [createCategory, { isLoading: isCreateLoading }] = useCreateCategoryMutation();
  const [updateCategory, { isLoading: isUpdateLoading }] = useUpdateCategoryMutation();

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { isDirty, isValid },
  } = useForm<ICreateFolderFields>({
    mode: 'onBlur',
    resolver: yupResolver<ICreateFolderFields>(createFolderSchema),
    defaultValues: {
      name: '',
      color: FOLDER_COLORS[0],
    },
  });

  const onHandleCreateSubmit = handleSubmit(async ({ name, color }) => {
    let result;
    if (categoryId) {
      result = await updateCategory({ categoryID: categoryId, categoryName: name, colorCode: color });
    } else {
      result = await createCategory({ categoryName: name, colorCode: color });
    }

    if (!('error' in result)) {
      onClose?.();
      onSave?.(result.data);
    }
  });

  useEffect(() => {
    if (!open) {
      reset();
    } else if (category) {
      setValue('name', category.categoryName);
      setValue('color', category.colorCode);
    }
  }, [open, reset, category, setValue]);

  return (
    <Modal
      open={open}
      title={isEdit ? t('modal.edit') : t('modal.createFolder')}
      confirmTitle={t('modal.save')}
      onClose={onClose}
      isSaving={isCreateLoading || isUpdateLoading}
      isDisabled={isCreateLoading || isUpdateLoading || !isDirty || !isValid}
      onConfirm={onHandleCreateSubmit}
    >
      <Stack
        direction="column"
        py={3}
        spacing={3}
      >
        <Stack
          direction="column"
          spacing={1}
        >
          <Typography
            fontSize={14}
            fontWeight={600}
            color="text.primary"
          >
            {t('modal.name')}
          </Typography>
          <Controller
            name="name"
            control={control}
            render={({
              field,
              fieldState: { error: fieldError },
            }) => (
              <FormControl error={!!fieldError} fullWidth>
                <TextField
                  helperText={fieldError?.message}
                  error={!!fieldError}
                  placeholder={t('modal.placeholderName')}
                  fullWidth
                  {...field}
                />
              </FormControl>
            )}
          />
        </Stack>
        <Controller
          name="color"
          control={control}
          render={({
            field: { onChange, value, onBlur },
            fieldState: { error: fieldError },
          }) => (
            <FormControl error={!!fieldError} fullWidth>
              <FormLabel
                sx={{ mb: 1 }}
                id="radio-buttons-color-group-label"
              >
                <Typography
                  fontSize={14}
                  fontWeight={600}
                  color="text.primary"
                >
                  {t('modal.chooseColor')}
                </Typography>
              </FormLabel>
              <RadioGroup
                row
                name="color"
                aria-labelledby="radio-buttons-color-group-label"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              >
                {FOLDER_COLORS.map((color) => (
                  <FormControlLabel
                    key={color}
                    value={color}
                    label={color}
                    sx={labelStyles}
                    control={<ColorRadio bgColor={color} checkedIcon={<CheckRoundedIcon sx={iconStyles} />} />}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          )}
        />
      </Stack>
    </Modal>
  );
}

export default CreateFolderModal;
