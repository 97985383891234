import { useMemo, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import LoaderBackdrop from '../../components/LoaderBackdrop/LoaderBackdrop';
import drawerWidth from '../../constants/Common';
import { useDecksQuery } from '../../store/slices/deck/apis/mainDeckApi';
import StudySidebar from '../StudySidebar/StudySidebar';

import type { ReactElement } from 'react';

function StudyPage(): ReactElement {
  const { deckId } = useParams();

  const { data: allDecks } = useDecksQuery();
  const foundDeck = useMemo(
    () => (deckId
      ? allDecks?.find((deckItem) => deckItem.deckID === +deckId)
      : undefined),
    [allDecks, deckId],
  );

  const [isLoading, setIsLoading] = useState(false);
  const outletContext = useMemo(() => ({ isLoading, setIsLoading }), [isLoading, setIsLoading]);

  return (
    <Box>
      <LoaderBackdrop open={isLoading} />
      <StudySidebar
        drawerWidth={drawerWidth}
        deckName={foundDeck?.deckName}
      />
      <Stack
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          ml: `${drawerWidth}px`,
          width: `calc(100% - ${drawerWidth}px)`,
          minHeight: '100vh',
        }}
      >
        <Outlet context={outletContext} />
      </Stack>
    </Box>
  );
}

export default StudyPage;
