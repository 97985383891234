import RequestUrl from '../../../../constants/RequestUrl';
import mainApi from '../../../apis/mainApi';

import type ISearch from '../interfaces/ISearch';

const mainSearchApi = mainApi.injectEndpoints({
  endpoints: (build) => ({
    search: build.query<ISearch, string>({
      query: (search) => ({
        url: RequestUrl.search(search),
      }),
    }),
  }),
});

export const {
  useSearchQuery,
} = mainSearchApi;

export default mainSearchApi;
