export default {
  category: () => '/category',
  categoryId: (id: number) => `/category/${id}`,
  deck: () => '/deck',
  oneDeck: (deckId: number) => `/deck/${deckId}`,
  oneDeckInCategory: (categoryId: number, deckId: number) => `/deck/${categoryId}/${deckId}`,
  search: (value: string) => `/search/${value}`,
  studysessionStart: (deckId: number) => `/studysession/start/${deckId}`,
  studysessionEnd: (deckId: number, sessionId: number) => `/studysession/end/${deckId}/${sessionId}`,
  selectAnswer: (sessionId: number, flashcardId: number) => `/studysession/detail/${sessionId}/${flashcardId}`,
  convertTextToCards: () => '/convert/texttoflashcards',
  activities: () => '/activities',
};
