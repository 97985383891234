import RequestUrl from '../../../../constants/RequestUrl';
import mainApi from '../../../apis/mainApi';
import ACTIVITY_TAG from '../../../tags/study';

import type IActivity from '../interfaces/IActivity';
import type IAnswer from '../interfaces/IAnswer';
import type IAnswerReq from '../interfaces/IAnswerReq';
import type IEndSessionReq from '../interfaces/IEndSessionReq';
import type IEndSessionResp from '../interfaces/IEndSessionResp';
import type IStartSessionReq from '../interfaces/IStartSessionReq';
import type IStartSessionResp from '../interfaces/IStartSessionResp';

const mainStudyApi = mainApi.injectEndpoints({
  endpoints: (build) => ({
    studysessionStart: build.mutation<IStartSessionResp, IStartSessionReq>({
      query: ({ deckId }) => ({
        url: RequestUrl.studysessionStart(deckId),
        method: 'POST',
        body: {
          studySessionType: 1,
        },
      }),
      invalidatesTags: [ACTIVITY_TAG],
    }),

    studysessionEnd: build.mutation<IEndSessionResp, IEndSessionReq>({
      query: ({ deckId, sessionId }) => ({
        url: RequestUrl.studysessionEnd(deckId, sessionId),
        method: 'POST',
      }),
      invalidatesTags: [ACTIVITY_TAG],
    }),

    selectAnswer: build.mutation<IAnswer, IAnswerReq>({
      query: ({ sessionId, flashcardId, correctlyAnswered }) => ({
        url: RequestUrl.selectAnswer(sessionId, flashcardId),
        method: 'POST',
        body: {
          correctlyAnswered,
        },
      }),
    }),

    activities: build.query<IActivity[], void>({
      query: () => ({
        url: RequestUrl.activities(),
      }),
      providesTags: [ACTIVITY_TAG],
      transformResponse: (response: { activityItems: IActivity[] }) => response.activityItems,
    }),
  }),
});

export const {
  useStudysessionStartMutation,
  useStudysessionEndMutation,
  useSelectAnswerMutation,
  useActivitiesQuery,
} = mainStudyApi;

export default mainStudyApi;
