import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import type { PaperProps } from '@mui/material/Paper';

const ActivityBarRoot = styled(
  Paper,
)<PaperProps>(({ theme }) => ({
  height: '100%',
  minHeight: 200,
  padding: theme.spacing(3),
  borderRadius: theme.spacing(3),
  backgroundColor: theme.palette.secondary.main,
}));

export default ActivityBarRoot;
