import common from './common';
import decks from './decks';
import home from './home';
import login from './login';
import modal from './modal';
import study from './study';

export default {
  common,
  home,
  login,
  modal,
  decks,
  study,
};
