import type { ILinkButton } from '../interfaces/ILinkButton';

import '../sass/LinkButton.css';

function LinkButton({
  className,
  onClick,
  label,
  href,
  backgroundColor,
  ...rest
}: ILinkButton) {
  return (
    <a
      href={href}
      className={className}
      style={{ backgroundColor }}
      onClick={onClick}
      {...rest}
    >
      <span>{label}</span>
    </a>
  );
}

export default LinkButton;
