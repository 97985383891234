import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import type { ButtonProps } from '@mui/material/Button';

const SwiperButton = styled(
  Button,
)<ButtonProps>(({ theme }) => ({
  minWidth: 24,
  height: 24,
  padding: 'unset',
  borderRadius: theme.spacing(0.5),
}));

export default SwiperButton;
