import FolderIcon from '@mui/icons-material/Folder';
import Stack from '@mui/material/Stack';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import type IFolderSmall from './interfaces/IFolderSmall';

function FolderSmall({ name, color, m }: IFolderSmall) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      m={m}
      py={1.2}
      px={1.5}
      borderRadius={3}
      sx={{ backgroundColor: alpha(color, 0.3) }}
    >
      <FolderIcon sx={{ color }} />
      <Typography
        fontSize={12}
        fontWeight={600}
        sx={{ color }}
      >
        {name}
      </Typography>
    </Stack>
  );
}

export default FolderSmall;
