import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { getContent } from '../../utils/helpers';
import Modal from '../Modal/Modal';
import ProgressBar from './ProgressBar';

import type IProgressModal from './interfaces/IProgressModal';

import { ReactComponent as CelebrationIcon } from '../../assets/images/celebration.svg';

function ProgressModal({
  open,
  percentage,
  onClose,
  onConfirm,
}: IProgressModal) {
  const { t } = useTranslation();

  const { text, color } = getContent(percentage);

  return (
    <Modal
      open={open}
      title={t('modal.completedDeck')}
      confirmTitle={t('modal.complete')}
      onClose={onClose}
      onConfirm={onConfirm}
      hideActions={percentage === 100}
    >
      <Stack
        pt={6}
        pb={6}
        alignItems="center"
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          direction="column"
          spacing={2}
          sx={{ maxWidth: 269 }}
        >
          {percentage === 100
            ? <CelebrationIcon /> : (
              <ProgressBar
                percentage={percentage}
                color={color}
              />
            )}
          <Typography
            variant="body2"
            textAlign="center"
            color="text.secondary"
          >
            {text}
          </Typography>
        </Stack>
      </Stack>
    </Modal>
  );
}

export default ProgressModal;
