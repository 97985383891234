import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '../../types/TStore';
import type ICategoryState from './interfaces/ICategoryState';

const selectCategory = (state: RootState): ICategoryState => state.category;

const selectCategoryModalData = createSelector(
  selectCategory,
  ({ isOpenModal, categoryId }) => ({ isOpenModal, categoryId }),
);

export default selectCategoryModalData;
