import { Link } from 'react-router-dom';

import Social from '../../pages/Landing/components/Social';

import styles from '../../pages/Landing/sass/Footer.module.scss';

function Footer() {
  return (
    <footer className={styles.footer}>
      <div className="container">
        <div className={styles.content}>
          <div className="social">
            <Social />
          </div>
          <div className={styles.nav}>
            <nav>
              <ul className={styles.flex}>
                <li>All Rights Reserved</li>
                <li>
                  <Link to="/terms">Terms and Conditions</Link>
                </li>
                <li>
                  <Link to="/privacy">Privacy Policy</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
