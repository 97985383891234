import React from 'react';
import ReactDOM from 'react-dom';

import type { ReactNode } from 'react';

import styles from '../sass/Modal.module.scss';

interface IModalProps {
  children: ReactNode,
  isOpen: boolean,
  onToggle: (e: React.MouseEvent<HTMLElement>) => void,
}

function Modal({
  children,
  isOpen,
  onToggle,
}: IModalProps) {
  const modal = (
    <div className={styles.overlay} onClick={onToggle} aria-hidden="true">
      <div className={styles.modal} onClick={(e) => e.stopPropagation()} aria-hidden="true">
        <div className={styles.box}>
          <button onClick={onToggle} className={styles.close} type="button" aria-label="Close" />
          {children}
        </div>
      </div>
    </div>
  );

  return isOpen ? ReactDOM.createPortal(modal, document.body) : null;
}

export default Modal;
