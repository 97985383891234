import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grow from '@mui/material/Grow';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { useConvertMutation } from '../../store/slices/convert/apis/mainConvertApi';
import removeNonASCIAndNewlines from '../../utils/helpers';
import { aiModalSchema } from '../../utils/validators';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import AIModalRoot from './AIModalRoot';
import AIProgress from './AIProgress';
import ErrorBanner from './ErrorBanner';

import type IAIModal from './interfaces/IAIModal';
import type IAIModalFields from './interfaces/IAIModalFields';

import { ReactComponent as AlertIcon } from '../../assets/images/alert.svg';
import { ReactComponent as StarsIcon } from '../../assets/images/stars.svg';

function AIModal({
  open,
  onConfirmClose,
  onSave,
}: IAIModal) {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);

  const [convertAiText] = useConvertMutation();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IAIModalFields>({
    mode: 'onBlur',
    resolver: yupResolver<IAIModalFields>(aiModalSchema),
    defaultValues: {
      text: '',
    },
  });

  const onHandleToggleModal = () => setOpenModal((prev) => !prev);

  const onHandleCloseModals = () => {
    setOpenModal(false);
    onConfirmClose();
  };

  const onHandleCreateSubmit = handleSubmit(async ({ text }) => {
    setProgress(20);
    const timer = setInterval(() => setProgress((prevProgress) => Math.min(prevProgress + 10, 90)), 300);
    const response = await convertAiText({ text });
    clearInterval(timer);
    if ('data' in response) {
      setProgress(100);
      onSave(response.data.flashcardItems);
    }
  });

  useEffect(() => {
    reset();
    setProgress(0);
  }, [open, reset]);

  return (
    <>
      <AIModalRoot
        className="modal"
        open={open}
        onClose={() => setOpenModal(true)}
        title={t('modal.aiModalTitle')}
        confirmTitle={t('modal.convert')}
        onConfirm={onHandleCreateSubmit}
        titleIcon={<StarsIcon width={32} height={32} />}
        isDisabled={!!progress}
      >
        {progress
          ? (
            <Grow in={!!progress}>
              <Box>
                <AIProgress progress={progress} />
              </Box>
            </Grow>
          ) : (
            <Stack
              py={3}
              spacing={3}
            >
              <Box
                p={2}
                sx={(theme) => ({
                  border: `1px solid ${theme.palette.secondary.light}`,
                  borderRadius: theme.spacing(1),
                  backgroundColor: theme.palette.primary.contrastText,
                })}
              >
                <Typography
                  variant="h2"
                  fontSize={14}
                  fontWeight={600}
                  lineHeight="160%"
                >
                  {t('modal.information')}
                </Typography>
                <Typography
                  fontSize={12}
                  lineHeight="160%"
                  color="text.secondary"
                >
                  {t('modal.aiModalHint')}
                </Typography>
              </Box>
              <Stack
                direction="column"
                spacing={1}
              >
                <Typography
                  fontSize={14}
                  fontWeight={600}
                  color="text.primary"
                >
                  {t('modal.name')}
                </Typography>
                <Controller
                  name="text"
                  control={control}
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState: { error: fieldError },
                  }) => (
                    <FormControl error={!!fieldError} fullWidth>
                      <Box sx={{ position: 'relative' }}>
                        <TextField
                          error={!!fieldError}
                          fullWidth
                          multiline
                          value={value}
                          onBlur={onBlur}
                          onChange={(e) => onChange(removeNonASCIAndNewlines(e.target.value))}
                          minRows={15}
                          maxRows={15}
                          sx={(theme) => ({
                            '.MuiInputBase-input': {
                              padding: '8px 16px 24px',
                              fontSize: 12,
                              borderRadius: 'inherit',
                              backgroundColor: theme.palette.primary.contrastText,
                            },
                          })}
                          aria-describedby="helper-text"
                        />
                        <Typography
                          fontSize={12}
                          color="primary.light"
                          p={0.5}
                          sx={(theme) => ({
                            position: 'absolute',
                            right: '24px',
                            bottom: 4,
                            borderRadius: theme.spacing(1),
                            backgroundColor: theme.palette.primary.contrastText,
                          })}
                        >
                          {`${value?.length || 0} / 5000`}
                        </Typography>
                      </Box>
                    </FormControl>
                  )}
                />
              </Stack>
              {errors.text?.message ? (
                <Grow in={!!errors.text?.message}>
                  <ErrorBanner
                    direction="row"
                    spacing={1.5}
                    alignItems="center"
                  >
                    <AlertIcon width={32} height={32} />
                    <Typography
                      variant="body2"
                      lineHeight="24px"
                      color="text.secondary"
                    >
                      {errors.text?.message}
                    </Typography>
                  </ErrorBanner>
                </Grow>
              ) : null}
            </Stack>
          )}
      </AIModalRoot>
      <ConfirmModal
        open={openModal}
        onClose={onHandleToggleModal}
        onConfirm={onHandleCloseModals}
      />
    </>
  );
}

export default AIModal;
