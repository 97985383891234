import { useCallback, useEffect, useState } from 'react';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import Stack from '@mui/material/Stack';
import { useSwiper } from 'swiper/react';

import SwiperButton from './SwiperButton';

import type { SwiperEvents } from 'swiper/types';
import type ISwiperNavButtons from './interfaces/ISwiperNavButtons';

const events = ['init', 'resize', 'slideChangeTransitionEnd'];

function SwiperNavButtons({
  isAbsolute,
}: ISwiperNavButtons) {
  const swiper = useSwiper();
  const [positions, setPositions] = useState({
    isStart: true,
    isEnd: true,
  });

  const onHandlePositions = useCallback(() => {
    setPositions({
      isStart: swiper.isBeginning,
      isEnd: swiper.isEnd,
    });
  }, [swiper]);

  useEffect(() => {
    events.forEach((event) => {
      swiper.on(event as keyof SwiperEvents, onHandlePositions);
    });

    return () => {
      events.forEach((event) => {
        swiper.off(event as keyof SwiperEvents, onHandlePositions);
      });
    };
  }, [onHandlePositions, swiper]);

  const onHandleNext = () => {
    swiper.slideNext();
    onHandlePositions();
  };

  const onHandlePrev = () => {
    swiper.slidePrev();
    onHandlePositions();
  };

  return (
    <Stack
      direction="row"
      spacing={0.5}
      justifyContent="space-between"
      sx={isAbsolute ? {
        position: 'absolute',
        top: -48,
        right: 0,
        zIndex: 300,
      } : undefined}
    >
      <SwiperButton
        aria-label="previous"
        variant="outlined"
        onClick={onHandlePrev}
        disabled={positions.isStart}
      >
        <ChevronLeftRoundedIcon sx={{ fontSize: 16 }} />
      </SwiperButton>
      <SwiperButton
        aria-label="next"
        variant="outlined"
        onClick={onHandleNext}
        disabled={positions.isEnd}
      >
        <ChevronRightRoundedIcon sx={{ fontSize: 16 }} />
      </SwiperButton>
    </Stack>
  );
}

export default SwiperNavButtons;
