import { useState } from 'react';
import { Link } from 'react-router-dom';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

import AppRoutes from '../../../constants/AppRoutes';
import useScrollBlock from '../../../hooks/lock-scroll';
import LinkButton from './LinkButton';

import { ReactComponent as LightningIcon } from '../../../assets/images/lightning.svg';

import styles from '../sass/Navbar.module.scss';

function Navbar() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [blockScroll, allowScroll] = useScrollBlock();

  if (isNavExpanded) {
    blockScroll();
  } else {
    allowScroll();
  }

  const onOpenMenu = () => {
    setIsNavExpanded(!isNavExpanded);
  };

  const closeMenu = () => {
    setIsNavExpanded(false);
  }

  return (
    <nav className={styles.navigation}>
      <a href={AppRoutes.landing} className={styles.brand}>
        <div className={styles.box}>
          <LightningIcon />
        </div>
        <span>vFlash</span>
      </a>
      <div
        className={
          isNavExpanded
            ? `${styles.menu} ${styles.expanded}`
            : `${styles.menu}`
        }
      >
        <ul>
          <li>
            <Link to="/about" onClick={closeMenu}>About Us</Link>
          </li>
          <li>
            <Link to="/contact" onClick={closeMenu}>Contact Us</Link>
          </li>
        </ul>
        {isNavExpanded === true && (
          <div className={styles.menuBtns}>
            {/* <LinkButton
              className="primary navbar"
              label="Login"
              href={AppRoutes.login}
            /> */}
          </div>
        )}
      </div>
      <div className={styles.btns}>
        {/* {isNavExpanded === false && (
          <LinkButton
            className="primary navbar"
            label="Login"
            href={AppRoutes.login}
          />
        )} */}
      </div>
      <button
        type="button"
        className={styles.hamburger}
        onClick={onOpenMenu} 
      >
        {isNavExpanded ? (
          <CloseRoundedIcon />
        ) : (
          <MenuRoundedIcon />
        )}
      </button>
    </nav>
  );
}

export default Navbar;
