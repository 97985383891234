import AppRoutes from '../../constants/AppRoutes';
import LinkButton from './components/LinkButton';
import Modal from './components/Modal';

import Crafted from '../../assets/images/AIcrafted.svg';
import Customizable from '../../assets/images/Customizable.svg';
import Hero from '../../assets/images/Home.svg';
import Interactive from '../../assets/images/Interactive.svg';
import Stars from '../../assets/images/stars.svg'

import styles from './sass/Landing.module.scss';
import { useState } from 'react';
import WaitlistForm from './components/WaitlistForm';



function Landing() {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  return (
    <>
      <section className={styles.hero}>
        <div className="container">
          <div className={styles.box}>
            <div>
              <h1 className={styles.title}>
                Hate studying? Let vFlash's{' '}
                <img src={Stars} alt="Stars" className={styles.stars} /> 
                <span>AI</span> make it simple and stress-free.
              </h1>
              <p className="section-text">
                  vFlash does the hard work for you—automatically creating flashcards, quizzes, and even AI-powered voice study sessions.
              </p>
              <LinkButton
                onClick={toggleModal}
                href='#'
                //href={AppRoutes.login}
                className="register"
                label="Join the Waitlist"
              />
              <Modal isOpen={isModalOpen} onToggle={toggleModal}>
                <WaitlistForm />
              </Modal>
            </div>
            <div>
              <img src={Hero} alt="Flash" className={styles.heroIMG} />
            </div>
          </div>
        </div>
      </section>
      <section className={styles.learning}>
        <div className="container">
          <div className={styles.content}>
            <h2 className="section-title">
              Revolutionize Your Learning Experience with AI-Powered Flashcards
            </h2>
            <p className="section-text">
              Welcome to vFlash - built for people who hate studying.
              Let our AI turn your study materials into smart, effortless
              flashcards and make studying something you'll never dread again.
              Experience a faster, easier way to learn with vFlash.
            </p>
            <LinkButton onClick={toggleModal} href="#" className="primary" label="Join the Waitlist" />
          </div>
          <div className={styles.blocks}>
            <div className={styles.flex}>
              <div className={styles.col}>
                <div className={`${styles.item} ${styles.rose}`}>
                  <div className={styles.title}>
                    <h3>Interactive Study Modes</h3>
                  </div>
                  <p>
                    Engage in dynamic study sessions with AI using
                    speech-to-text and voice recognition- your very own study
                    partner!
                  </p>
                </div>
              </div>
              <div className={styles.col}>
                <div className={`${styles.item} ${styles.orange}`}>
                  <div className={styles.title}>
                    <h3>AI-Powered Flashcards</h3>
                  </div>
                  <p>Instantly create flashcards from your study materials.</p>
                </div>
                <div className={`${styles.item} ${styles.green}`}>
                  <div className={styles.title}>
                    <h3>Effortless Learning</h3>
                  </div>
                  <p>
                    Whether you’re exploring new subjects or preparing for
                    exams, vFlash is your ultimate learning companion.
                  </p>
                </div>
              </div>
              <div className={styles.col}>
                <div className={`${styles.item} ${styles.lavender}`}>
                  <div className={styles.title}>
                    <h3>Stay Motivated</h3>
                  </div>
                  <p>
                    Keep your learning journey on track by effortlessly
                    monitoring the number of days you’ve dedicated to studying.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.quality}>
        <div className="container">
          <div className={styles.content}>
            <h2 className="section-title">Studying doesn’t have to be hard</h2>
            <p className="section-text">
                vFlash was made for people who hate studying. With our AI-powered tools, 
                we do the heavy lifting—turning your materials into smart flashcards, 
                creating quizzes, and even guiding you through voice study sessions. 
                Forget the frustration and discover how easy learning can be with vFlash.
            </p>
            <LinkButton onClick={toggleModal} href="#" className="primary" label="Join the waitlist" />
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <img src={Crafted} alt="Flash" />
            </div>
            <div className={styles.col}>
              <div className={styles.box}>
                <h3 className="middle-title">AI-crafted flashcards</h3>
                <p className="section-text">
                  Easily convert your study material into customized flashcards.
                  Provide a chapter or a block of text, and let vFlash&apos;s AI do
                  the rest.
                </p>
                <LinkButton
                  onClick={toggleModal}
                  href="#"
                  className="primary"
                  label="Join the waitlist"
                />
              </div>
            </div>
          </div>
          <div className={`${styles.row} ${styles.reverse}`}>
            <div className={styles.col}>
              <div className={styles.box}>
                <h3 className="middle-title">Interactive study mode </h3>
                <p className="section-text">
                  Discover our friendly interactive study mode, where learning
                  feels like a chat with a study buddy. Navigate through
                  flashcards, ask questions, and make studying a breeze.
                </p>
                <LinkButton
                  onClick={toggleModal}
                  href="#"
                  className="primary"
                  label="Join the waitlist"
                />
              </div>
            </div>
            <div className={styles.col}>
              <img src={Interactive} alt="Flash" />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <img src={Customizable} alt="Flash" />
            </div>
            <div className={styles.col}>
              <div className={styles.box}>
                <h3 className="middle-title">Customizable experience</h3>
                <p className="section-text">
                  Arrange, edit, and manage your flashcards with user-friendly
                  tools, enhancing your learning efficiency.
                </p>
                <LinkButton
                  onClick={toggleModal}
                  href="#"
                  className="primary"
                  label="Join the waitlist"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.start}>
        <div className="container">
          <div className={styles.content}>
            <h2 className="section-title">Join the waitlist today!</h2>
            <p className="section-text">
              Join the vFlash waitlist today and be notified when it’s time to
              start your AI-enhanced study experience.
            </p>
            <LinkButton
              onClick={toggleModal}
              href="#"
              //href={AppRoutes.login}
              className="register"
              label="Join the waitlist"
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default Landing;
