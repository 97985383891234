import { useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperNavButtons from '../../../../components/SwiperNavButtons/SwiperNavButtons';
import ActionsPanel from '../ActionsPanel/ActionsPanel';
import CardSlider from '../CardSlider/CardSlider';
import StudyProgress from '../StudyProgress/StudyProgress';

import type ICardsSection from './interfaces/ICardsSection';

function CardsSection({
  deck,
  answers,
  onSelectAnswer,
}: ICardsSection) {
  const [flipIndex, setFlipIndex] = useState<number | null>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const isButtonDisabled = !!answers.find(({ flashcardID }) => {
    if (deck) {
      const cardId = deck.flashcardItems[currentIndex].flashcardID;

      return flashcardID === cardId;
    }
    return false;
  });

  const onHandleFlip = (index: number | null) => {
    if (typeof flipIndex === 'number') {
      setFlipIndex(null);
    } else {
      setFlipIndex(index);
    }
  };

  const onHandleSelectAnswer = (answer: boolean) => {
    if (deck) {
      const cardId = deck.flashcardItems[currentIndex].flashcardID;

      onSelectAnswer(cardId, answer);
    }
  };

  return (
    <Stack
      direction="row"
      alignSelf="center"
      justifyContent="center"
    >
      <Stack
        spacing={5}
        justifyContent="center"
        sx={{ width: 400 }}
      >
        <Box>
          <StudyProgress
            length={deck?.flashcardItems.length || 0}
            currentIndex={currentIndex}
          />
        </Box>
        <Box>
          <Swiper
            slidesPerView="auto"
            spaceBetween={16}
            modules={[Navigation]}
            navigation
            onInit={(swiper) => setCurrentIndex(swiper.activeIndex)}
            onSlideChange={(swiper) => setCurrentIndex(swiper.activeIndex)}
          >
            {deck?.flashcardItems.map(({ flashcardID, side1, side2 }, index) => (
              <SwiperSlide
                key={flashcardID}
                style={{ boxSizing: 'border-box' }}
              >
                <CardSlider
                  index={index}
                  currentIndex={flipIndex}
                  frontSide={side2}
                  backSide={side1}
                />
              </SwiperSlide>
            ))}
            <Box mb={1.5} slot="container-start">
              <SwiperNavButtons />
            </Box>
            <Box mt={5}>
              <ActionsPanel
                isButtonDisabled={isButtonDisabled}
                onFlip={onHandleFlip}
                onSelectAnswer={onHandleSelectAnswer}
              />
            </Box>
          </Swiper>
        </Box>
      </Stack>
    </Stack>
  );
}

export default CardsSection;
