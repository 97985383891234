export default {
  decksTitle: 'My Decks',
  createDeck: 'Creating a new deck',
  updateDeck: 'Update the deck',
  assistantAI: 'AI Assistant',
  name: 'Name',
  deckNameHint: 'Write a name for emaple “basic words of English”',
  saveToFolder: 'Save to folder',
  createNewFolder: 'Create new folder',
  addCard: 'Add a card',
  frontSide: 'Front side',
  backSide: 'Back side',
  cardInputHint: 'Text',
  saveDeck: 'Save deck',
  cardsCount: '{{count}} cards',
  decksCount: '{{count}} decks',
  addFolderText: 'Add your first folder',
  cards_one: '{{count}} card',
  cards_other: '{{count}} cards',
  decks_one: '{{count}} decks',
  decks_other: '{{count}} decks',
  addDeckText: 'Make your first deck of cards',
};
