import { Outlet } from 'react-router-dom';

import CookieMessage from './Cookie';
import Footer from './Footer';
import Header from './Header';

import './sass/LendingGlobal.css';

function LandingLayout() {
  return (
    <div className="wrapper">
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer />
      <CookieMessage />
    </div>
  );
}

export default LandingLayout;
