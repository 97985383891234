import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import clsx from 'clsx';

import ModalRoot from './ModalRoot';

import type IModal from './interfaces/IModal';

import styles from './sass/Modal.module.scss';

function Modal({
  open,
  title,
  children,
  confirmTitle,
  showCancel,
  titleIcon,
  className,
  isDisabled,
  isSaving,
  hideActions,
  onClose,
  onConfirm,
}: IModal) {
  const { t } = useTranslation();

  return (
    <ModalRoot
      className={className}
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="dialog-title"
    >
      <Stack
        direction="row"
        alignItems="center"
      >
        {titleIcon ? (
          <Stack alignItems="center" pl={2}>
            {titleIcon}
          </Stack>
        ) : null}
        <DialogTitle
          className={clsx(styles.title, {
            [styles.withIcon]: titleIcon,
          })}
          id="dialog-title"
        >
          {title}
        </DialogTitle>
      </Stack>
      <Button
        className={styles.close}
        variant="outlined"
        aria-label="close"
        onClick={onClose}
      >
        <CloseIcon sx={{ fontSize: 12 }} />
      </Button>
      <DialogContent dividers>
        {children}
      </DialogContent>
      {!hideActions ? (
        <DialogActions className={styles.actions}>
          {showCancel ? (
            <Button
              variant="outlined"
              onClick={onClose}
            >
              {t('modal.cancel')}
            </Button>
          ) : null}
          <LoadingButton
            variant="contained"
            disabled={isDisabled}
            onClick={onConfirm}
            loading={isSaving}
          >
            {confirmTitle}
          </LoadingButton>
        </DialogActions>
      ) : null}
    </ModalRoot>
  );
}

export default Modal;
