import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import type ICardItem from './interfaces/ICardItem';

function CardItem({
  text,
  isBack,
}: ICardItem) {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={(theme) => ({
        width: 400,
        height: 280,
        padding: '50px 40px',
        borderRadius: theme.spacing(2),
        backgroundColor: isBack ? theme.palette.primary.main : theme.palette.secondary.main,
        border: `1px solid ${theme.palette.secondary.light}`,
        color: isBack ? theme.palette.primary.contrastText : theme.palette.primary.main,
      })}
    >
      <Typography
        textAlign="center"
      >
        {text}
      </Typography>
    </Stack>
  );
}

export default CardItem;
