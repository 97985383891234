import { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

import type IOutletContext from '../layout/Page/interfaces/IOutletContext';

const usePageLoader = (loading: boolean) => {
  const { setIsLoading, isLoading } = useOutletContext<IOutletContext>();
  useEffect(() => {
    setIsLoading(loading);
  }, [loading, setIsLoading]);

  useEffect(() => () => {
    setIsLoading(false);
  }, [setIsLoading]);

  return isLoading;
};

export default usePageLoader;
