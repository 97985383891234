import { useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';

import CreateFolderModal from '../../components/CreateFolderModal/CreateFolderModal';
import LoaderBackdrop from '../../components/LoaderBackdrop/LoaderBackdrop';
import drawerWidth from '../../constants/Common';
import { useAppDispatch, useAppSelector } from '../../store/hooks/useApp';
import selectCategoryModalData from '../../store/slices/category/selectors';
import { closeCategoryModal } from '../../store/slices/category/slice';
import Sidebar from '../Sidebar/Sidebar';
import Toolbar from '../Toolbar/Toolbar';

import type { ReactElement } from 'react';

/**
 * Main page layout
 */
function Page(): ReactElement {
  const dispatch = useAppDispatch();
  const { isOpenModal: isOpenCategoryModal, categoryId } = useAppSelector(selectCategoryModalData);

  const onCloseCategoryModal = () => {
    dispatch(closeCategoryModal());
  };

  const [isLoading, setIsLoading] = useState(false);
  const outletContext = useMemo(() => ({ isLoading, setIsLoading }), [isLoading, setIsLoading]);

  return (
    <Box>
      <LoaderBackdrop open={isLoading} />
      <Toolbar drawerWidth={drawerWidth} />
      <Sidebar drawerWidth={drawerWidth} />
      <CreateFolderModal
        open={isOpenCategoryModal}
        categoryId={categoryId}
        onClose={onCloseCategoryModal}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          ml: `${drawerWidth}px`,
          width: `calc(100% - ${drawerWidth}px)`,
        }}
      >
        <Outlet context={outletContext} />
      </Box>
    </Box>
  );
}

export default Page;
