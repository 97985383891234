export default {
  delete: 'Delete',
  actionDelete: 'Yes, delete it',
  deleteMsgTitle: 'Are you sure?',
  deleteMsgText: 'This action will delete all your information. You won’t be able to revert this!',
  cancel: 'Cancel',
  createFolder: 'Create new folder',
  name: 'Name',
  placeholderName: 'For example “irregular verbs in English”',
  chooseColor: 'Choose color',
  save: 'Save',
  edit: 'Edit',
  aiModalTitle: 'Create cards together with AI',
  information: 'Information',
  aiModalHint: 'Please copy the text you want me to translate and place it in the box.',
  convert: 'Convert',
  close: 'Yes close',
  aiLoadingModalHint: 'Your cards are being created, do not interrupt the download',
  completedDeck: 'Completed your deck',
  complete: 'Complete',
  completeFull: 'Congratulations! You have successfully completed your study.',
  completeHigh: 'Great results, keep it up – most of the answers were correct.',
  completeMedium: 'Your knowledge is at an intermediate level. Keep growing and improving.',
  completeLow: 'Low knowledge, but consistent repetition leads to future success.',
  correct: 'Correct',
};
