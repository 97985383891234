import { useMemo } from 'react';

import usePageLoader from '../../hooks/usePageLoader';
import { useAppDispatch } from '../../store/hooks/useApp';
import { useCategoriesQuery } from '../../store/slices/category/apis/mainCategoryApi';
import { openCreateCategoryModal } from '../../store/slices/category/slice';
import { useDecksQuery } from '../../store/slices/deck/apis/mainDeckApi';
import FoldersView from './FoldersView';

import type { ReactElement } from 'react';
import type IDecksByFolders from './interfaces/IDecksByFolders';

function Folders(): ReactElement {
  const { data: categories, isLoading: isLoadingDecks } = useCategoriesQuery();
  const { data: decks, isLoading: isLoadingCategories } = useDecksQuery();
  const dispatch = useAppDispatch();

  usePageLoader(isLoadingDecks || isLoadingCategories);

  const decksByFolders = useMemo(
    () => decks?.reduce((acc: IDecksByFolders, deck) => ({
      ...acc,
      [deck.categoryID]: {
        decksCount: (acc?.[deck.categoryID]?.decksCount || 0) + 1,
        cardsCount: (acc?.[deck.categoryID]?.cardsCount || 0) + (deck.flashcardItems?.length || 0),
      },
    }), {}),
    [decks],
  );

  const onCreateFolder = () => {
    dispatch(openCreateCategoryModal());
  };

  return (
    <FoldersView
      categories={categories}
      onCreateFolder={onCreateFolder}
      decksByFolders={decksByFolders}
    />
  );
}

export default Folders;
