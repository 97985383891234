// Make sure to run npm install @formspree/react
// For more help visit https://formspr.ee/react-help
import React from 'react';
import { useForm, ValidationError } from '@formspree/react';

import styles from './sass/Landing.module.scss';

function WaitlistForm() {
  const [state, handleSubmit] = useForm("mzbnznrp");
  if (state.succeeded) {
      return <p>You’re on the list! We can’t wait to show you how studying can actually be easy.</p>;
  }
  return (
    <form onSubmit={handleSubmit}>
      <div style={{ display: 'flex', marginTop: '16px', justifyContent: 'center' }}>
        <label htmlFor="email" className="hero">
          Enter your e-mail address to be notified when we launch!
        </label>
      </div>
      <div style={{ display: 'flex', marginTop: '16px', justifyContent: 'center' }}>
        <input
          id="email"
          type="email" 
          name="email"
          required
          style={{
            width: '300px', // Adjust width
            height: '40px', // Adjust height
            fontSize: '16px', // Optional: Adjust font size
            padding: '8px', // Optional: Add padding for better spacing
          }}
        />
      </div>
      <div>
        <ValidationError 
          prefix="Email" 
          field="email"
          errors={state.errors}
        />
        <ValidationError 
          prefix="Message" 
          field="message"
          errors={state.errors}
        />
      </div>
      <div style={{ display: 'flex', marginTop: '16px', justifyContent: 'center' }}>
        <button type="submit" disabled={state.submitting} className="primary" >
          Join the waitlist!
        </button>
      </div>
    </form>
  );
}

export default WaitlistForm;