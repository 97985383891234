import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { UNAUTHORIZED } from '../../constants/StatusCodes';
import { removeTokens } from '../slices/auth/slice';
import CATEGORY_TAG from '../tags/category';
import DECK_TAG from '../tags/deck';
import ACTIVITY_TAG from '../tags/study';

import type {
  BaseQueryFn, FetchArgs, FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react';
import type IError from '../types/IError';
import type { RootState } from '../types/TStore';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_API,
  prepareHeaders: (headers, { getState }) => {
    const rootState = getState() as RootState;
    const { accessToken } = rootState.auth;
    if (accessToken) {
      headers.set('authorization', `Bearer ${accessToken}`);
    }
    return headers;
  },
}) as BaseQueryFn<string | FetchArgs, unknown, IError, {}, FetchBaseQueryMeta>;

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, IError> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === UNAUTHORIZED) {
    api.dispatch(removeTokens());
  }
  return result;
};

const mainApi = createApi({
  reducerPath: 'mainApi',
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  tagTypes: [CATEGORY_TAG, DECK_TAG, ACTIVITY_TAG],
});

export default mainApi;
