import { t } from 'i18next';

const removeNonASCIAndNewlines = (text?: string) => {
  let textToFormat = '';

  if (text) {
    textToFormat = text.replace(/[\n\r]/g, ' ').replace(/[\u{0080}-\u{FFFF}]/gu, ' ');
  }

  return textToFormat;
};

export const getContent = (percents: number) => {
  if (percents === 100) {
    return ({ text: t('modal.completeFull'), color: '#66C61C' });
  }
  if (percents >= 75) {
    return ({ text: t('modal.completeHigh'), color: '#66C61C' });
  }
  if (percents >= 51) {
    return ({ text: t('modal.completeMedium'), color: '#F79009' });
  }
  return ({ text: t('modal.completeLow'), color: '#F04438' });
};

export default removeNonASCIAndNewlines;
