import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

import type { LinearProgressProps } from '@mui/material/LinearProgress';

const ProgressBarRoot = styled(LinearProgress)<LinearProgressProps>({
  height: 8,
  borderRadius: 8,
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 8,
  },
});

export default ProgressBarRoot;
