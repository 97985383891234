import CardContent from '@mui/material/CardContent';
import { styled } from '@mui/material/styles';

const CardContentDeck = styled(
  CardContent,
)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  ':last-child': {
    paddingBottom: theme.spacing(2),
  },
}));

export default CardContentDeck;
