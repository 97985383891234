import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const PaperRoot = styled(
  Paper,
)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(3),
  borderRadius: theme.spacing(1.5),
  backgroundColor: theme.palette.secondary.main,
  overflow: 'hidden',
}));

export default PaperRoot;
