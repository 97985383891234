import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

const FolderChip = styled(
  Chip,
)(({ theme }) => ({
  height: 'auto',
  minHeight: theme.spacing(3),
  padding: '2.5px 8px',
  fontSize: theme.typography.pxToRem(12),
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.secondary.main,
  '& .MuiChip-label': {
    padding: 0,
  },
}));

export default FolderChip;
