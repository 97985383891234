import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const CreateFolderWrapper = styled(
  Box,
)(({ theme }) => ({
  maxWidth: 247,
  backgroundColor: 'transparent',
  transition: theme.transitions.create('filter'),
  '&:hover': {
    filter: 'drop-shadow(0px 4px 6px rgb(229, 230, 232)) drop-shadow(0px 12px 16px rgb(242, 243, 244))',
  },
}));

export default CreateFolderWrapper;
