import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Modal from '../Modal/Modal';

import type IConfirmModal from './interfaces/IConfirmModal';

import { ReactComponent as PinIcon } from '../../assets/images/pin.svg';
import { ReactComponent as StarsIcon } from '../../assets/images/stars.svg';

function ConfirmModal({
  open,
  onClose,
  onConfirm,
}: IConfirmModal) {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      title={t('modal.aiModalTitle')}
      confirmTitle={t('modal.close')}
      onClose={onClose}
      onConfirm={onConfirm}
      showCancel
      titleIcon={<StarsIcon width={32} height={32} />}
    >
      <Stack
        pt={6}
        pb={5}
        alignItems="center"
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          direction="column"
          spacing={2}
          sx={{ maxWidth: 279 }}
        >
          <PinIcon />
          <Typography
            variant="body1"
            textAlign="center"
            fontWeight={700}
          >
            {t('modal.deleteMsgTitle')}
          </Typography>
          <Typography
            variant="body2"
            textAlign="center"
            color="text.secondary"
          >
            {t('modal.deleteMsgText')}
          </Typography>
        </Stack>
      </Stack>
    </Modal>
  );
}

export default ConfirmModal;
