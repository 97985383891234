import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';

import CreateFolder from '../../components/CreateFolder/CreateFolder';
import Folder from '../../components/Folder/Folder';
import Panel from '../../components/Panel/Panel';

import type { ReactElement } from 'react';
import type IFolders from './interfaces/IFolders';

function FoldersView({
  categories, onCreateFolder, decksByFolders,
}: IFolders): ReactElement {
  const { t } = useTranslation();
  return (
    <Panel title={t('common.foldersTitle')}>
      {categories?.length ? (
        <Grid container spacing={2}>
          {categories.map(({ categoryID, categoryName, colorCode }) => (
            <Grid item key={categoryID}>
              <Folder
                categoryId={categoryID}
                title={categoryName}
                color={colorCode}
                cardsCount={decksByFolders?.[categoryID]?.cardsCount || 0}
                decksCount={decksByFolders?.[categoryID]?.decksCount || 0}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <CreateFolder onCreate={onCreateFolder} />
      )}
    </Panel>
  );
}

export default FoldersView;
