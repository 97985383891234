import { MOTIVATION_STATUS } from '../../constants/Common';

export default {
  weekActivity: 'Your activity in a week',
  latestActions: 'Latest actions',
  motivationBanner: {
    [MOTIVATION_STATUS.NO_ACTIVITIES]: {
      title: 'Hey!',
      text: `Create your flash cards now and discover a new,
             exciting path to knowledge. This is your time to shine and reach
              great heights!`,
    },
    [MOTIVATION_STATUS.NO_STUDY_SESSIONS]: {
      title: 'Let\'s get studying!',
      text: 'Are you ready to start studying? Pull up one of your decks and get in your first study session of the week!',
    },
    [MOTIVATION_STATUS.WELL_DONE]: {
      title: 'Well done!',
      text: `Regular repetition provides the opportunity for
              self-control and assessment of your progress, keep up the good work!`,
    },
  },
};
