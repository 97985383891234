import styles from './sass/About.module.scss';

function About() {
    return (
      <section className={styles.about}>
        <div className={styles.container}>
            <div className={styles.fourth}>
                <div className={styles.row}>
                    <div className={styles.head}>
                        <h2>About Us</h2>
                    </div>
                    <p>
                        Welcome to <strong>vFlash, LLC</strong> – where innovation meets simplicity. Our mission is to make studying accessible, efficient, and enjoyable for everyone. 
                        Whether you're a student, professional, or lifelong learner, vFlash provides intuitive tools to transform the way you learn.
                    </p>
                    <p>
                        Founded on the belief that education should be personalized and engaging, we leverage the latest advancements in technology to create a seamless learning experience.
                        From interactive flashcards to AI-powered quizzes, we’re dedicated to helping you succeed.
                    </p>
                    <p>
                        At vFlash, we are more than just a study platform. We are a community of learners and innovators passionate about making a difference.
                        Our team works tirelessly to bring you the features you need, whether that’s preparing for certifications, acing exams, or mastering new skills.
                    </p>
                    <p>
                        Thank you for being part of our journey. Together, we can redefine how the world learns.
                    </p>
                    <p>
                        <strong>Stay Connected:</strong> Reach out with any feedback or suggestions. We’d love to hear from you!
                        <div><a href="mailto: support@vflash.ai">support@vflash.ai</a></div>
                    </p>
                </div>
            </div>
        </div>
      </section>
    );
}

export default About;
