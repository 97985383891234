import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import type { BoxProps } from '@mui/material/Box';

const CardSectionHead = styled(
  Box,
)<BoxProps>(({ theme }) => ({
  padding: `${theme.spacing(1.5)} ${theme.spacing(2)} ${theme.spacing(3)}`,
  backgroundColor: theme.palette.primary.contrastText,
  borderRadius: theme.spacing(1.5),
}));

export default CardSectionHead;
