import RequestUrl from '../../../../constants/RequestUrl';
import mainApi from '../../../apis/mainApi';
import CATEGORY_TAG from '../../../tags/category';

import type ICategory from '../interfaces/ICategory';
import type ICreateCategory from '../interfaces/ICreateCategory';

const mainCategoryApi = mainApi.injectEndpoints({
  endpoints: (build) => ({
    categories: build.query<ICategory[], void>({
      query: () => ({
        url: RequestUrl.category(),
      }),
      providesTags: [CATEGORY_TAG],
    }),
    createCategory: build.mutation<ICategory, ICreateCategory>({
      query: (body) => ({
        url: RequestUrl.category(),
        method: 'POST',
        body,
      }),
      invalidatesTags: [CATEGORY_TAG],
    }),
    updateCategory: build.mutation<ICategory, ICategory>({
      query: ({ categoryID, ...body }) => ({
        url: RequestUrl.categoryId(categoryID),
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result) => (result ? [{ type: CATEGORY_TAG, id: result.categoryID }] : [CATEGORY_TAG]),
    }),
    deleteCategory: build.mutation<unknown, number>({
      query: (id) => ({
        url: RequestUrl.categoryId(id),
        method: 'DELETE',
      }),
      invalidatesTags: [CATEGORY_TAG],
    }),
  }),
});

export const {
  useCategoriesQuery,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
} = mainCategoryApi;

export default mainCategoryApi;
