import { createSlice } from '@reduxjs/toolkit';

import mainStudyApi from './apis/mainStudyApi';

import type IStudySlice from './interfaces/IStudySlice';

export const initialState: IStudySlice = {
  answers: [],
};

const studySlice = createSlice({
  name: 'studySession',
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder): void => {
    builder.addMatcher(
      mainStudyApi.endpoints.selectAnswer.matchFulfilled,
      (state, { payload }) => {
        state.answers = [...state.answers, payload];
      },
    );
  },
});

export const {
  resetState,
} = studySlice.actions;

export default studySlice.reducer;
