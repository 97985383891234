import { useTranslation } from 'react-i18next';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import CreateFolderRoot from './CreateFolderRoot';
import CreateFolderWrapper from './CreateFolderWrapper';

import type ICreateFolder from './interfaces/ICreateFolder';

import { ReactComponent as FolderIcon } from '../../assets/images/folder.svg';

function CreateFolder({ onCreate }: ICreateFolder) {
  const { t } = useTranslation();

  return (
    <CreateFolderWrapper>
      <CreateFolderRoot
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <FolderIcon />
        <Typography
          mt={0.5}
          fontSize={12}
        >
          {t('decks.addFolderText')}
        </Typography>
        <Button
          sx={{ minWidth: 92, mt: 1.5, p: '5.5px 16px' }}
          variant="outlined"
          startIcon={<AddRoundedIcon />}
          onClick={onCreate}
        >
          <Typography fontSize={12}>
            {t('common.create')}
          </Typography>
        </Button>
      </CreateFolderRoot>
    </CreateFolderWrapper>
  );
}

export default CreateFolder;
