import { ReactComponent as DiscordIcon } from '../../../assets/images/discord.svg';
import { ReactComponent as FacebookIcon } from '../../../assets/images/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../../assets/images/instagram.svg';
import { ReactComponent as LinkedinIcon } from '../../../assets/images/linkedin.svg';

import styles from '../sass/Social.module.scss';

function Social() {
  return (
    <ul className={styles.social}>
      <li>
        <a href="https://discord.gg/dZG3sAUm">
          <DiscordIcon />
          <p> Join our Discord Community</p>
        </a>
      </li>
      {/* <li>
        <a href="/public">
          <LinkedinIcon />
        </a>
      </li>
      <li>
        <a href="/public">
          <FacebookIcon />
        </a>
      </li>
      <li>
        <a href="/public">
          <InstagramIcon />
        </a>
      </li> */}
    </ul>
  );
}

export default Social;
