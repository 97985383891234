import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import FolderIcon from '@mui/icons-material/Folder';
import LayersRoundedIcon from '@mui/icons-material/LayersRounded';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MUIToolbar from '@mui/material/Toolbar';

import AppRoutes from '../../constants/AppRoutes';
import { useAppDispatch } from '../../store/hooks/useApp';
import { openCreateCategoryModal } from '../../store/slices/category/slice';
import ProfileMenu from './ProfileMenu';
import Search from './Search';

import type { ReactElement, SyntheticEvent } from 'react';
import type IToolbar from './interfaces/IToolbar';

function Toolbar({ drawerWidth }: IToolbar): ReactElement {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const onClickCreate = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  const onHandleToggleModal = (e?: SyntheticEvent) => {
    e?.preventDefault();
    e?.stopPropagation();
    dispatch(openCreateCategoryModal());
    onCloseMenu();
  };

  return (
    <AppBar
      position="static"
      sx={{
        width: `calc(100% - ${drawerWidth}px)`,
        ml: `${drawerWidth}px`,
      }}
    >
      <MUIToolbar sx={{ gap: 3 }}>
        <Search />

        <Button
          variant="contained"
          startIcon={<AddRoundedIcon />}
          onClick={onClickCreate}
        >
          {t('common.create')}
        </Button>
        <Menu
          open={open}
          onClose={onCloseMenu}
          anchorEl={anchorEl}
        >
          <MenuItem
            component={Link}
            to={AppRoutes.decksCreate}
            onClick={onCloseMenu}
          >
            <LayersRoundedIcon fontSize="small" sx={{ mr: 1 }} />
            {t('common.deck')}
          </MenuItem>
          <MenuItem onClick={onHandleToggleModal}>
            <FolderIcon fontSize="small" sx={{ mr: 1 }} />
            {t('common.folder')}
          </MenuItem>
        </Menu>
        <ProfileMenu />
      </MUIToolbar>
    </AppBar>
  );
}

export default Toolbar;
