import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import type IBarItem from './interfaces/IBarItem';

const BarItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isFilled',
})<IBarItem>(({ theme, isFilled }) => ({
  width: 32,
  height: '100%',
  flexGrow: 2,
  borderRadius: theme.spacing(4),
  backgroundColor: isFilled ? theme.palette.primary.main : theme.palette.secondary.light,
  opacity: isFilled ? 1 : 0.5,
}));

export default BarItem;
