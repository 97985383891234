import ReactCardFlip from 'react-card-flip';

import CardItem from '../../../../components/CardItem/CardItem';

import type ICardSlider from './interfaces/ICardSlider';

function CardSlider({
  index,
  currentIndex,
  frontSide,
  backSide,
}: ICardSlider) {
  return (
    <ReactCardFlip
      isFlipped={index === currentIndex}
      flipDirection="horizontal"
      infinite
    >
      <CardItem text={frontSide} />

      <CardItem isBack text={backSide} />
    </ReactCardFlip>
  );
}

export default CardSlider;
