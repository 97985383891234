import { useMemo } from 'react';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';

import AppRoutes from '../../constants/AppRoutes';
import { useDecksQuery } from '../../store/slices/deck/apis/mainDeckApi';
import SidebarLabel from './SidebarLabel';

import type IFolderItem from './interfaces/IFolderItem';

function FolderItem({
  categoryID, categoryName, colorCode,
}: IFolderItem) {
  const { data: decks } = useDecksQuery();

  const decksForCategory = useMemo(() => decks?.filter(
    (deck) => deck.categoryID === categoryID,
  ), [decks, categoryID]);

  return (
    <TreeItem
      nodeId={AppRoutes.buildDecksInFolder(categoryID)}
      label={(
        <SidebarLabel
          label={categoryName}
          startIcon={<SquareRoundedIcon style={{ color: colorCode, fontSize: 12 }} />}
        />
      )}
      sx={{
        [`& .${treeItemClasses.group}`]: {
          borderLeftWidth: 2,
          borderLeftStyle: 'solid',
          marginLeft: 5,
        },
      }}
    >
      {decksForCategory?.map((deck) => (
        <TreeItem
          key={deck.deckID}
          nodeId={AppRoutes.buildStudySession(deck.deckID)}
          label={deck.deckName}
        />
      ))}
    </TreeItem>
  );
}

export default FolderItem;
