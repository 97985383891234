import { useTranslation } from 'react-i18next';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import CreateDeckRoot from './CreateDeckRoot';

import type ICreateDeck from './interfaces/ICreateDeck';

import { ReactComponent as DeckIcon } from '../../assets/images/deck.svg';

function CreateDeck({ onCreate }: ICreateDeck) {
  const { t } = useTranslation();

  return (
    <CreateDeckRoot
      direction="column"
      alignItems="center"
    >
      <DeckIcon />
      <Typography
        mt={0.5}
        fontSize={12}
      >
        {t('decks.addDeckText')}
      </Typography>
      <Button
        sx={{ minWidth: 92, mt: 1.5, p: '5.5px 16px' }}
        variant="outlined"
        startIcon={<AddRoundedIcon />}
        onClick={onCreate}
      >
        <Typography fontSize={12}>
          {t('common.create')}
        </Typography>
      </Button>
    </CreateDeckRoot>
  );
}

export default CreateDeck;
