import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeIcon from '@mui/icons-material/Mode';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CardActions from '@mui/material/CardActions';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';

import AppRoutes from '../../constants/AppRoutes';
import { useDeleteDeckMutation } from '../../store/slices/deck/apis/mainDeckApi';
import CardHover from '../CardHover/CardHover';
import DeleteModal from '../DeleteModal/DeleteModal';
import Dropdown from '../Dropdown/Dropdown';
import FolderChip from '../FolderChip/FolderChip';
import FolderSmall from '../FolderSmall/FolderSmall';
import CardContentDeck from './CardContentDeck';
import CardHeaderDeck from './CardHeaderDeck';

import type IDeck from './interfaces/IDeck';

function Deck({
  countOfCards,
  title,
  folder,
  deckID,
}: IDeck) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [deleteDeck, { isLoading }] = useDeleteDeckMutation();

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const onHandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const onHandleClose = () => {
    setAnchorEl(null);
  };

  const onCardClick = () => {
    if (deckID && !open) {
      navigate(AppRoutes.buildStudySession(deckID));
    }
  };

  const onDeleteFolder = async () => {
    await deleteDeck(deckID);
    setIsOpenDeleteModal(false);
  };

  const onOpenConfirmDelete = () => {
    onHandleClose();
    setIsOpenDeleteModal(true);
  };

  const onCloseConfirmDelete = () => {
    setIsOpenDeleteModal(false);
  };

  return (
    <>
      <CardHover shadow={4} sx={{ width: 247, cursor: 'pointer' }} onClick={onCardClick}>
        {!!folder && <FolderSmall color={folder.colorCode} name={folder.categoryName} m={1} />}
        <CardContentDeck>
          <CardHeaderDeck title={title} />
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <FolderChip label={t('decks.cards', { count: countOfCards })} />
            <CardActions sx={{ p: 0 }}>
              <IconButton
                sx={{ p: 0 }}
                id="dropdown-button"
                aria-controls={open ? 'dropdown-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                aria-label="open dropdown"
                onClick={onHandleClick}
              >
                <MoreVertIcon sx={{ fontSize: 20 }} />
              </IconButton>
              <Dropdown
                id="dropdown-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={onHandleClose}
                MenuListProps={{
                  'aria-labelledby': 'dropdown-button',
                }}
                anchorOrigin={{
                  vertical: 50,
                  horizontal: 30,
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem
                  component={Link}
                  to={AppRoutes.buildDecksEdit(deckID)}
                >
                  <ModeIcon />
                  {t('common.edit')}
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={onOpenConfirmDelete}>
                  <DeleteIcon />
                  {t('common.delete')}
                </MenuItem>
              </Dropdown>
            </CardActions>
          </Stack>
        </CardContentDeck>
      </CardHover>
      <DeleteModal
        isSaving={isLoading}
        open={isOpenDeleteModal}
        onClose={onCloseConfirmDelete}
        onConfirm={onDeleteFolder}
      />
    </>
  );
}

export default Deck;
