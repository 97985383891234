const drawerWidth = 276;

export const FOLDER_COLORS = ['#528BFF', '#875BF7', '#66C61C', '#FDB022', '#EF6820', '#FD6F8E', '#79716B'];

export const SEARCH_TYPE = {
  FOLDER: 'folder',
  DECK: 'deck',
} as const;

export const MOTIVATION_STATUS = {
  NO_ACTIVITIES: 'NO_ACTIVITIES',
  NO_STUDY_SESSIONS: 'NO_STUDY_SESSIONS',
  WELL_DONE: 'WELL_DONE',
} as const;

export default drawerWidth;
