import RequestUrl from '../../../../constants/RequestUrl';
import mainApi from '../../../apis/mainApi';
import DECK_TAG from '../../../tags/deck';
import ACTIVITY_TAG from '../../../tags/study';

import type ICreateDeckReq from '../interfaces/ICreateDeckReq';
import type IDeck from '../interfaces/IDeck';
import type IUpdateDeckReq from '../interfaces/IUpdateDeckReq';

const mainDeckApi = mainApi.injectEndpoints({
  endpoints: (build) => ({
    decks: build.query<IDeck[], void>({
      query: () => ({
        url: RequestUrl.deck(),
      }),
      providesTags: [DECK_TAG],
    }),

    oneDeck: build.query<IDeck, number>({
      query: (id) => ({
        url: RequestUrl.oneDeck(id),
      }),
      providesTags: (result) => (result ? [{ type: DECK_TAG, id: result.deckID }] : [DECK_TAG]),
    }),

    createDeck: build.mutation<IDeck, ICreateDeckReq>({
      query: (body) => ({
        url: RequestUrl.deck(),
        method: 'POST',
        body,
      }),
      invalidatesTags: [DECK_TAG, ACTIVITY_TAG],
    }),

    updateDeck: build.mutation<IDeck, IUpdateDeckReq>({
      query: ({ deckID, categoryID, ...body }) => ({
        url: RequestUrl.oneDeckInCategory(categoryID, deckID),
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result) => (result
        ? [{ type: DECK_TAG, id: result.deckID, ACTIVITY_TAG }]
        : [DECK_TAG, ACTIVITY_TAG]),
    }),

    deleteDeck: build.mutation<unknown, number>({
      query: (id) => ({
        url: RequestUrl.oneDeck(id),
        method: 'DELETE',
      }),
      invalidatesTags: [DECK_TAG, ACTIVITY_TAG],
    }),
  }),
});

export const {
  useDecksQuery,
  useOneDeckQuery,
  useCreateDeckMutation,
  useUpdateDeckMutation,
  useDeleteDeckMutation,
} = mainDeckApi;

export default mainDeckApi;
