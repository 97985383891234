import * as yup from 'yup';

import type { ICard } from '../pages/DecksCreate/interfaces/IDeckCreateFields';

export const createDeckSchema = yup.object().shape({
  deckName: yup.string().required('Required field').max(100),
  categoryID: yup.string().required('Required field'),
  flashcardItems: yup.array().of<ICard>(
    yup.object().shape({
      side1: yup.string().required('Required field').max(200),
      side2: yup.string().required('Required field').max(200),
    }),
  ).default([]).required(),
});

export const createFolderSchema = yup.object().shape({
  name: yup.string().required('Required field').max(100),
  color: yup.string().required('Required field'),
});

export const aiModalSchema = yup.object().shape({
  text: yup.string().required('Required field').max(5000, 'Maximum symbol count 5000'),
});
