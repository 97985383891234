import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import type ISidebarLabel from './interfaces/ISidebarLabel';

function SidebarLabel({
  label, startIcon, endIcon, labelColor,
}: ISidebarLabel) {
  return (
    <Stack spacing={1} direction="row" alignItems="center" justifyContent="space-between" pl={1}>
      <Button
        sx={{
          width: '100%',
          padding: 0,
          justifyContent: 'flex-start',
          '&:hover': {
            backgroundColor: 'unset',
          },
        }}
        disableRipple
        variant="text"
        startIcon={startIcon}
      >
        <Typography variant="body1" color={labelColor}>
          {label}
        </Typography>
      </Button>
      {endIcon}
    </Stack>
  );
}

export default SidebarLabel;
