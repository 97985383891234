import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import type IProgressBar from './interfaces/IProgressBar';

const labelStyles = {
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

function ProgressBar({
  percentage,
  color,
}: IProgressBar) {
  const { t } = useTranslation();

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <Box sx={{ width: 100, height: 100 }}>
        <CircularProgressbar
          value={percentage}
          strokeWidth={5}
          styles={buildStyles({
            strokeLinecap: 'round',
            pathTransitionDuration: 0.5,
            pathColor: color,
            trailColor: '#EAECF0',
          })}
        />
      </Box>
      <Stack sx={labelStyles}>
        <Typography
          fontWeight={600}
          color="text.primary"
        >
          {percentage}
        </Typography>
        <Typography
          variant="body2"
          fontWeight={500}
          color="text.secondary"
        >
          {t('modal.correct')}
        </Typography>
      </Stack>
    </Box>
  );
}

export default ProgressBar;
