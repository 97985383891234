import './i18n';
import 'swiper/css';
import 'swiper/css/pagination';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import AppRoutes from './constants/AppRoutes';
import LandingLayout from './layout/LandingLayout/LandingLayout';
import PageProtected from './layout/Page/PageProtected';
import StudyPage from './layout/StudyPage/StudyPage';
import Dashboard from './pages/Dashboard/Dashboard';
import Decks from './pages/Decks/Decks';
import DecksCreate from './pages/DecksCreate/DecksCreate';
import Folders from './pages/Folders/Folders';
import Landing from './pages/Landing/Landing';
import Login from './pages/Login/Login';
import Privacy from './pages/Privacy/Privacy';
import Terms from './pages/Terms/Terms';
import Contact from './pages/Contact/Contact';
import About from './pages/About/About';
import Study from './pages/Study/Study';

import type { ReactElement } from 'react';

import './styles/global.scss';

function App(): ReactElement {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route path={AppRoutes.login} element={<Login />} />
          <Route element={<LandingLayout />}>
            <Route index element={<Landing />} />
            <Route path={AppRoutes.privacy} element={<Privacy />} />
            <Route path={AppRoutes.terms} element={<Terms />} />
            <Route path={AppRoutes.about} element={<About />} />
            <Route path={AppRoutes.contact} element={<Contact />} />
          </Route>
          <Route element={<PageProtected />}>
            <Route path={AppRoutes.home} element={<Dashboard />} />
            <Route path={AppRoutes.folders} element={<Folders />} />
            <Route path={AppRoutes.decks} element={<Decks />} />
            <Route path={AppRoutes.decksInFolder} element={<Decks />} />
            <Route path={AppRoutes.decksCreate} element={<DecksCreate />} />
            <Route path={AppRoutes.decksEdit} element={<DecksCreate />} />
          </Route>
          <Route element={<PageProtected layout={<StudyPage />} />}>
            <Route path={AppRoutes.studySession} element={<Study />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
