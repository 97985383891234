import Theme from '../../constants/Theme';

export default {
  welcomeMessage: 'Try to build something great',
  unknownError: 'Unknown error',
  layoutSearchInput: 'Find a deck or folder...',
  create: 'Create',
  edit: 'Edit',
  delete: 'Delete',
  home: 'Home',
  myDecks: 'My Decks',
  myFolders: 'My Folders',
  deck: 'Deck',
  folder: 'Folder',
  noMatchesFound: 'No matches found',
  somethingWentWrong: 'Something went wrong',
  theme: {
    [Theme.light]: 'Light',
    [Theme.dark]: 'Dark',
  },
  back: 'Back',
  start: 'Start',
  signOut: 'Sign Out',
  foldersTitle: 'My Folders',
};
