import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { skipToken } from '@reduxjs/toolkit/query';

import AppRoutes from '../../constants/AppRoutes';
import { SEARCH_TYPE } from '../../constants/Common';
import { testIdSearch } from '../../constants/TestId';
import { useSearchQuery } from '../../store/slices/search/apis/mainSearchApi';

function Search() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);

  const { currentData, isFetching } = useSearchQuery(search.length >= 2 ? search : skipToken);

  const options = useMemo(() => [
    ...(currentData?.deckItems.map((item) => ({
      ...item, id: item.deckID, type: SEARCH_TYPE.DECK, label: item.deckName,
    })) || []),
    ...(currentData?.categoryItems.map((item) => ({
      ...item, id: item.categoryID, type: SEARCH_TYPE.FOLDER, label: item.categoryName,
    })) || []),
  ], [currentData]);

  return (
    <Autocomplete
      sx={{ flex: 1 }}
      open={!!open && search.length >= 2}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, value) => option.id === value.id && option.type === value.type}
      groupBy={(option) => option.type}
      clearOnBlur={false}
      getOptionLabel={(option) => option.label}
      onInputChange={(e, value) => setSearch(value)}
      onChange={(e, value) => value
        && navigate(value.type === SEARCH_TYPE.FOLDER ? AppRoutes.buildDecksInFolder(value.id) : AppRoutes.decks)}
      inputValue={search}
      options={options}
      loading={isFetching}
      noOptionsText={t('common.noMatchesFound')}
      renderInput={(params) => (
        <TextField
          {...params}
          data-testid={testIdSearch}
          size="small"
          placeholder={t('common.layoutSearchInput')}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchRoundedIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderGroup={(params) => (
        <Box key={params.key}>
          {params.group !== SEARCH_TYPE.DECK && (
            <Divider flexItem />
          )}
          <Typography
            variant="body1"
            color="text.disabled"
            px={2}
            pt={1}
            fontSize={10}
          >
            {t(`common.${params.group}`)}
          </Typography>
          {params.children}
        </Box>
      )}
    />
  );
}

export default Search;
