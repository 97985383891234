import Radio from '@mui/material/Radio';
import { styled } from '@mui/material/styles';

import type IColorRadio from './interfaces/IColorRadio';

const ColorRadio = styled(Radio, {
  shouldForwardProp: (prop) => prop !== 'bgColor',
})<IColorRadio>(({ theme, bgColor }) => ({
  width: 40,
  height: 40,
  borderRadius: theme.spacing(1),
  backgroundColor: bgColor,
  '&:hover': {
    backgroundColor: bgColor,
  },
  '& .MuiSvgIcon-root': {
    visibility: 'hidden',
  },
  '&.Mui-checked .MuiSvgIcon-root': {
    visibility: 'initial',
  },
}));

export default ColorRadio;
